import React, {useState, useContext} from 'react';
import RfmApi from '../api/rfm-api';
import {Context as navContext} from '../context/navContext';
import {Context as authContext} from '../context/authContext';

import styles from './pageContact.module.scss';

const PageContact = () => {

    const {cxChangePage} = useContext(navContext);
    var {state} = useContext(authContext);
    const authState = state;

    const [message, setMessage] = useState('');
    const [signUpMsg, setSignUpMsg] = useState('');
    const [disableSignUp, setDisableSignUp] = useState(false); 

/***
* Validate Message
* - at least 8 characters long
***/
const validateMessage = (uname) => {
    return (uname.length >= 8);
}

/***
 * Validate form contents
 * 
 ***/
const validateForm = (signUpData)=> {
    if (!validateMessage(message)) {
        setSignUpMsg ('Invalid Message Format!');
        return false;
    }

    return true;
}   

return (
    <div id={styles.contactWrap}>
        <h1 id={styles.head}>Get In Touch</h1>
            <h3 id={styles.subHead}>We welcome your input on any aspect of the Draft To Final site to assist us in improving your user experience.</h3>
            <div id={styles.contactForm}>
                <label id={styles.msgTitle} htmlFor='message'>Message</label>                            
                <textarea id='message' value={message} rows='15' onChange={(event)=> {setMessage(event.target.value)}}></textarea>

                <div id='signUpBtn'>
                    <button  id='btnSignUp' disabled={disableSignUp} onClick={async ()=>{
                        // console.log(email, fName, lName, uName, password, agreeTC);
                        const contactData={message, user: authState.stCurrentUser}; // create key/value object
                        if (validateForm(contactData)) { // only move on if form data is valid (as far as we can determine)
                            // console.log('And we\'re off!');
                            const response = await RfmApi.post('/routes/contactUs', contactData);
                            if (response.data.err) {
                                setSignUpMsg(response.data.msg + ' - mail server error.');
                            } else {
                                setSignUpMsg('Thank you for reaching out. We will contact you shortly.');
                                setMessage('');
                                setDisableSignUp(true);
                            }
                        }
                    }}>Send</button>
                </div>

                <div id='application-ok'>

                    {/* show user confirmation message */}
                    <p>{signUpMsg}</p>
                    {(signUpMsg=='Thank you for reaching out. We will contact you shortly.') 
                        ? <a className={styles.goHome} onClick={()=>{cxChangePage('Home')}}>Return to Home Page</a> 
                        : null 
                    }
                    
                </div>
            </div>
        
   </div>
)



}

export default PageContact;