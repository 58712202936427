import React, {useState, useContext} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import axCommInstance from '../api/rfm-api';
import {Context as excpContext} from '../context/excpContext';
import {Context as authContext} from '../context/authContext';

import styles from './modManageTags.module.css';


const ModalManageTags = (props) => {
/*
A Modal called from the Excerpt Sidebar to facilitate the selecting or creation of Tags
    plus editing and deleting of existing tags.
*/

    var {cxUpdateTags, cxUpdateExcerpts} = useContext(excpContext);
    var {state} = useContext(authContext);
    const authState = state;
    var {state} = useContext(excpContext);
    const excpState = state;

    const [rfmTags, setRfmTags] = useState({});
    const [tagName, setTagName] = useState('');
    const [tagReadOnly, setTagReadOnly] = useState(true);
    const [disableDel, setDisableDel] = useState(true);
    const [disableEdit, setDisableEdit] = useState(true);
    const [disableCC, setDisableCC] = useState(true);
    const [disableAdd, setDisableAdd] = useState(true);
    const [selectedTag, setSelectedTag] = useState('Select a Tag to Edit or Delete');
    const [tagBack, setTagBack] = useState('white');
    const [editActive, setEditActive] = useState('red');
    const [delActve, setDelActive] = useState('grey');
    const [edMode, setEdMode] = useState('none');
    const [msgTagExists, setMsgTagExists] = useState('');

    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.tagManageModal} className="modalContent"> 
                    <h3 id={styles.modTagTitle}>Manage Tags</h3>
                    {/* ----------------- Edit / Delete Tag ---------------------- */}
                    <SelectR    
                        className={styles.selExcpTags} 
                        type='tag' 
                        content={excpState.stTagArray} 
                        value={rfmTags} 
                        multi={false} 
                        fnSetValue={(valueObj)=>{
                            setRfmTags(valueObj);
                            setDisableDel(false);
                            setDisableEdit(false);
                            setDisableCC(true);
                            setSelectedTag(valueObj.value);
                        }}
                    />
                    <input  id={styles.tagSelected} 
                            value={selectedTag}
                            readOnly={tagReadOnly}
                            style={{backgroundColor: tagBack}}
                            onChange={(event)=> {
                                setSelectedTag(event.target.value);
                            }}     
                    >
                    </input>
                    <Button id='btnTagEdit' 
                            legend='Edit' 
                            disabled={disableEdit} 
                            backgroundColor={editActive}
                            clickFn= {()=> {
                            {/* -------------- Make input field editable  ------------- */}
                                setTagReadOnly(false);
                                setTagBack('lightyellow');
                                setDisableCC(false);
                                setDisableDel(true);
                                setDisableEdit(true);
                                setEditActive('coral');
                                setEdMode('edit');
                            }}/>
                    <Button id='btnTagDelete' legend='Delete' disabled={disableDel} clickFn= {()=> {
                        setTagBack('coral');
                        setDisableCC(false);
                        setDisableDel(true);
                        setDisableEdit(true);
                        setEdMode('del');
                    }}/>

                    <Button id='btnTagCancel' legend='Cancel' disabled={disableCC} clickFn= {()=> {
                        setTagReadOnly(true);
                        setDisableCC(true);
                        setDisableDel(true);
                        setDisableEdit(true);
                        setRfmTags({value: '', label: ''});
                        setSelectedTag('Select a Tag to Edit or Delete');
                        setTagBack('white');
                        setEdMode('none');
                        setMsgTagExists('');
                    }}/>
                    <Button id='btnTagConfirm' legend='Confirm' disabled={disableCC} clickFn= { async ()=> {
                        setDisableCC(true);
                        setTagReadOnly(true);
                        setTagBack('coral');
                        if (edMode == 'edit') {
                            /* Update the Tag if it has changed*/
                            if (rfmTags.value !== selectedTag) {
                                const tagData={oldName: rfmTags.value, newName: selectedTag, owner: authState.stCurrentUser.username}; 
                                const response = await axCommInstance.post('/routes/updateTag', tagData);
                                if (response.data == 'Tag Already Exists!') { // this should never occur
                                    setMsgTagExists('Tag Already Exists!'); 
                                    setDisableCC(false);
                                } else {
                                    // save this in an excerpt context
                                    setMsgTagExists('');
                                    cxUpdateTags(response.data); // calling Context
                                    // fetch the updated excerpts
                                    const rstExcps = await axCommInstance.get('/routes/fetchExcerpts/' + authState.stCurrentUser.username)
                                    cxUpdateExcerpts(rstExcps.data);
                                    // tidy up UI
                                    setRfmTags({value: '', label: ''});
                                    setSelectedTag('Select a Tag to Edit or Delete');
                                    setTagBack('white');
                                }
                            } else {
                                setMsgTagExists('Tag Already Exists!'); 
                                setDisableCC(false);
                            }
                        } else if (edMode == 'del') {
                            const tagData={name: rfmTags.value, owner: authState.stCurrentUser.username}; 
                            const response = await axCommInstance.post('/routes/deleteTag', tagData);
                            // save this in an excerpt context
                            cxUpdateTags(response.data); // calling Context
                            // frst fetch the updated excerpts
                            const freshExcerpts = await axCommInstance.get('/routes/fetchExcerpts/'+tagData.owner);
                            cxUpdateExcerpts(freshExcerpts.data);
                            setRfmTags({value: '', label: ''});
                            setSelectedTag('Select a Tag to Edit or Delete');
                            setTagBack('white');
                        }
                        setEdMode('none');
                    }}/>


                    {/* ----------------- Add Tag ---------------------- */}
                    <label id={styles.lblTagName} htmlFor='txtTagName'>Add A Tag</label>    
                    <input id={styles.txtTagName} value={tagName} onChange={(event)=> {
                        setTagName(event.target.value.toLowerCase());
                        if(event.target.value == '') {
                            setDisableAdd(true);    
                        } else {
                            setDisableAdd(false);
                        }
                    }}></input>
                    
                    <Button id='btnAddTag' disabled={disableAdd} legend='Add Tag' clickFn= {async ()=> {
                        const tagData={name: tagName, owner: authState.stCurrentUser.username}; 
                        console.log(tagName);
                        const response = await axCommInstance.post('/routes/saveTag', tagData);
                        if (response.data == 'Tag Already Exists!') {
                            setMsgTagExists('Tag Already Exists!'); 
                            setDisableCC(false);
                        } else {
                            // save this in an excerpt context
                            setMsgTagExists(''); 
                            cxUpdateTags(response.data); // calling Context
                            setTagName('');
                            setDisableAdd(true);
                            //props.fnToggleShow(false);
                        }
                    }}/>
                    <Button id={'btnClose'} legend='Close' clickFn= {()=> {
                        setRfmTags();
                        setMsgTagExists('');
                        setTagName('');
                        setDisableAdd(true);
                        props.fnToggleShow(false);
                    }}/>
                    <h5 id={styles.msgTagExists}>{msgTagExists}</h5>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalManageTags;