import React, {useState, useContext, useEffect} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import ModalAddTags from './modAddTags';
import {Context as excpContext} from '../context/excpContext';
import {Context as srcContext} from '../context/srcContext';
import {Context as authContext} from '../context/authContext';
import axCommInstance from '../api/rfm-api';

import isEmptyObj from '../helpers/isEmptyObj';
import ObjectCompare from '../helpers/objectCompare';
import arrayCompare from '../helpers/arrayCompare';

import styles from './modExpAdd.module.scss';

const ModalExpAdd = (props) => {

    var {state, cxUpdateTags, cxUpdateExcerpts, cxSetCurrentExcp} = useContext(excpContext);
    const excpState = state;
    var {state, cxUpdateSources} = useContext(srcContext);
    const srcState = state;
    var {state} = useContext(authContext);
    const authState = state;

    // console.log(state);

    const [expFormTitle, setExpFormTitle] = useState('');
    const [expFormBody, setExpFormBody] = useState('');
    const [expFormUse, setExpFormUse] = useState('');
    const [expFormNotes, setExpFormNotes] = useState('');
    const [expFormPages, setExpFormPages] = useState('');
    const [expFormSource, setExpFormSource] = useState('');
    const [expFormTags, setExpFormTags] = useState([]);
    const [showModAddTags, setShowModAddTags] = useState(false);
    // selectRs
    const [source, setSource] = useState('');
    const [tags, setTags] = useState([]);
    // form validation
    const [formError, setFormError] = useState('');


    useEffect(()=>{
        const wrap = async () => {  // added wrapper to stop React complaining; doesn't like async as first arg type


            if(excpState.stTagArray === null) {
                let response = await axCommInstance.get('/routes/fetchTags/'+authState.stCurrentUser.username);
                cxUpdateTags(response.data);
            } else {
                cxUpdateTags(excpState.stTagArray);
            }
            if(srcState.stSrcArray === null) {
                let response = await axCommInstance.get('/routes/fetchSources/'+authState.stCurrentUser.username);
                cxUpdateSources(response.data);
            } else {
                cxUpdateSources(srcState.stSrcArray);
            }
            // populate the form if we are in Edit mode, else clear it
            if (props.isEdit) {
                setExpFormTitle(excpState.stSelectedExcp.title);
                setExpFormBody(excpState.stSelectedExcp.body);
                setExpFormPages(excpState.stSelectedExcp.pages);
                setExpFormUse(excpState.stSelectedExcp.use);
                setExpFormNotes(excpState.stSelectedExcp.notes);
                /* -- source -- */
                setExpFormSource({value: excpState.stSelectedExcp.sourceName, label: excpState.stSelectedExcp.sourceName});
                /* -- tags -- */
                setExpFormTags(excpState.stSelectedExcp.tagName.map((tag)=>{
                    return {value: tag, label: tag}
                }));

            } else {
                if (isEmptyObj(excpState.stCurrentExcp)) {
                    setExpFormTitle('');
                    setExpFormBody('');
                    setExpFormPages('');
                    setExpFormUse('');
                    setExpFormNotes('');
                    setExpFormSource('');
                    setExpFormTags([]);
                }
                else {
                  setExpFormTitle(excpState.stCurrentExcp.title);
                  setExpFormBody(excpState.stCurrentExcp.body);
                  setExpFormPages(excpState.stCurrentExcp.pages);
                  setExpFormUse(excpState.stCurrentExcp.use);
                  setExpFormNotes(excpState.stCurrentExcp.notes);
                  setExpFormSource(excpState.stCurrentExcp.source);
                  setExpFormTags(excpState.stCurrentExcp.tags.map((tag)=>{
                    return {value: tag, label: tag}
                  }));
                }
            }
        }
        wrap();
    }, [props.tagIsAdded, excpState.stTagArray, srcState.stSrcArray, excpState.stSelectedExcp, props.isEdit]);

    const validateForm = () => {
    /*
    Check that all form fields comply with the model
    */
        if(expFormTitle == '') {
            setFormError('Error: Title cannot be blank');
            return false;
        }

        if(expFormBody == '') {
            setFormError('Error: You must provide some content in the Excerpt field');
            return false;
        }

        if(expFormSource == '') {
            setFormError('Error: You must select a Source to continue');
            return false;
        }

        setFormError('');
        return true;

    }

    if (props.show) {
        return (
            <div className="modal">
                <div id='excpModal' className="modalContent">
                    <h3 id='excpModalHead'>Add / Edit Excerpt</h3>
                    <h2 id={styles.errMsg}>{formError}</h2>
                    <label id='lblTitle' htmlFor='txtTitle'>Title</label>    
                    <input id='txtTitle' value={expFormTitle} onChange={(event)=> {setExpFormTitle(event.target.value)}}></input>

                    <label id='lblBody' htmlFor='txaBody'>Excerpt</label>
                    <textarea id='txaBody' rows='5' value={expFormBody} onChange={(event)=> {setExpFormBody(event.target.value)}}></textarea>

                    <label id='lblPages' htmlFor='txtPages'>Pages</label>
                    <input id='txtPages' value={expFormPages} onChange={(event)=> {setExpFormPages(event.target.value)}}></input>

                    <label id='lblUse' htmlFor='txaUse'>Use As</label>
                    <textarea id='txaUse' rows='5' value={expFormUse} onChange={(event)=> {setExpFormUse(event.target.value)}}></textarea>

                    <label id='lblNotes' htmlFor='txaNotes'>Notes</label>
                    <textarea id='txaNotes' rows='5' value={expFormNotes} onChange={(event)=> {setExpFormNotes(event.target.value)}}></textarea>

                    <div id='sourceSelect'>
                        <label id='lblSource' htmlFor='selSource'>Source</label>
                        <SelectR className='selExcpSource' type='src' content={srcState.stSrcArray} value={expFormSource} multi={false} fnSetValue={setExpFormSource}/>
                    </div>

                    <div id='tagSelect'>
                        <label id='lblTags' htmlFor='selTags'>Tags</label>
                        <SelectR className='selExcpTags' type='tag' content={excpState.stTagArray} value={expFormTags} multi={true} fnSetValue={setExpFormTags}/>
                    </div>
                    <Button id='btnExpAddTags' legend='Add Tag' clickFn={()=>{
                      cxSetCurrentExcp({
                        title  : expFormTitle,
                        pages  : expFormPages,
                        body   : expFormBody,
                        use    : expFormUse,
                        notes  : expFormNotes,
                        source : expFormSource,
                        tags   : expFormTags.map((tag)=>{
                            return tag.value;
                        })
                      })
                      setShowModAddTags(true)
                    }}/>


                    <Button id='btnExpAddConfirm' legend='Confirm' clickFn={async ()=>{
                        const expData={ owner:  authState.stCurrentUser.username,
                                        title:  expFormTitle,
                                        pages:  expFormPages,
                                        body:   expFormBody,
                                        use:    expFormUse,
                                        notes:  expFormNotes,
                                        source: expFormSource,
                                        tags:   expFormTags.map((tag)=>{
                                            return tag.value;
                                        })
                                    };
                        // write to server
                        if (validateForm()) {  // form validation
                            if (props.isEdit){
                                // check to see if anything on the form has changed
                                const tempExcpStateData =  // data coming from the database
                                    {  owner:  authState.stCurrentUser.username,
                                        title:  excpState.stSelectedExcp.title,
                                        pages:  excpState.stSelectedExcp.pages,
                                        body:   excpState.stSelectedExcp.body,
                                        use:    excpState.stSelectedExcp.use,
                                        notes:  excpState.stSelectedExcp.notes,
                                        source: excpState.stSelectedExcp.sourceName
                                    };
                                const tempExcpFormData = // data coming from the form
                                    { owner:  authState.stCurrentUser.username,
                                        title:  expFormTitle,
                                        pages:  expFormPages,
                                        body:   expFormBody,
                                        use:    expFormUse,
                                        notes:  expFormNotes,
                                        source: expFormSource.value
                                    };
                                const excpBodySame = ObjectCompare(tempExcpStateData, tempExcpFormData); 
                                const tempFormTags = expFormTags.map((tag)=>{
                                    return tag.value;
                                })
                                const excpTagsSame = arrayCompare(excpState.stSelectedExcp.tagName.sort(), tempFormTags.sort());
                                
                                if (excpBodySame && excpTagsSame) {
                                    // do nothing
                                    setFormError('Nothing has changed - nothing to do');
                                } else {
                   
                                    // save the update
                                    const response = await axCommInstance.post(
                                                        '/routes/updateExcerpt/'+ excpState.stSelectedExcp._id, expData);
                                    if (response.data == 'updateExcerptOK') {
                                        // fetch the updated excerpts list
                                        const foundExcerpts = await axCommInstance.get
                                                    ('/routes/fetchExcerpts/' + authState.stCurrentUser.username);
                                        // update the context
                                        cxUpdateExcerpts(foundExcerpts.data);
                                        setFormError('');
                                        cxSetCurrentExcp({});
                                        props.fnToggleShow(false);
                                    } else {
                                        setFormError(response.data.msg);
                                    }
                                }
                            } else {
                                const response = await axCommInstance.post('/routes/saveExcerpt', expData); // note-01
                                if (response.data == 'saveExcerptOK') {
                                    // fetch the updated excerpts list
                                    const foundExcerpts = await axCommInstance.get('/routes/fetchExcerpts/' + authState.stCurrentUser.username);
                                    // clear the local state
                                    setExpFormTitle('');
                                    setExpFormBody('');
                                    setExpFormUse('');
                                    setExpFormNotes('');
                                    setExpFormPages('');
                                    setExpFormSource('');
                                    setExpFormTags([]);
                                    setFormError('');
                                    // update the context
                                    cxUpdateExcerpts(foundExcerpts.data);
                                    cxSetCurrentExcp({});
                                    props.fnToggleShow(false);
                                } else {
                                    setFormError(response.data.msg);
                                }
                            }
                        }
                    }}/>

                    <Button id='btnExpAddCancel' legend='Cancel' clickFn={()=>{
                        props.fnToggleShow(false);
                        if (!props.isEdit) {
                            setExpFormTitle('');
                            setExpFormBody('');
                            setExpFormPages('');
                            setExpFormUse('');
                            setExpFormNotes('');
                            setExpFormSource('');
                            setExpFormTags([]);
                            cxSetCurrentExcp({});
                        }
                        setFormError('');
                    }}/>

                </div>
                <ModalAddTags id='modAddTags' isTagAdded={props.fnToggleTagIsAdded} show={showModAddTags} fnToggleShow={setShowModAddTags}/>
            </div>
        )
    } else {
        return null;
    }

}

export default ModalExpAdd;
