import createDataContext from './createDataContext';

const authReducer = (state, useAction) => {
    switch (useAction.type){

    /* ------------------- ??? --------------- */
        case 'userChange':
            return {...state, stCurrentUser: useAction.payload.currentUser};

        default:
            return state;

    }
}

/* ---------------------- Functions ------------------------------- */

const cxChangeUser = (dispatch) => {
    return (currentUser) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'userChange',
          payload: {currentUser} // from passed argument(s) from function call
      })
    }
  }


/* -----------------------Create Data Context ------------------------------ */

export const { Context, Provider } = createDataContext(   
    // arg 1 - the reducer
        authReducer, 

    // arg 2 - the function(s) that will perform the action
    {
        cxChangeUser
    },
    // arg 3 - initial state
    {
        stCurrentUser: null
    }

);

