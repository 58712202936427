import React, {useContext} from 'react';
import PageSignUp from '../pages/pageSignUp';
import { Context as navContext } from '../context/navContext';
// import axCommInstance from '../api/wp-api';
import styles from './elmVideoSignUp.module.scss';

const ElmVideoSignUp = () => {
    const {state, cxChangePage} = useContext(navContext);
    let currentPage = null;
    currentPage = <PageSignUp />;

    return (
            <div className={styles.elmWrap}>
                <div className = {styles.title}>Sign Up Today</div>
                <div className={styles.subHead}>Be informed when the course becomes available</div>
                <div className={styles.bodyText}>This course is currently under development. Sign up to be notified when this and other courses become available, and take advanteage of early bird discounts.</div>
                <button  id={styles.btnVidSignUp} onClick={()=>{
                    cxChangePage('Sign Up');
                }}>Sign Up</button>
            </div>
        )

}
export default ElmVideoSignUp;