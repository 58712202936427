import React, {useState, useContext} from 'react';
import ArtTestContenful01 from '../articles/artTestContentful-01';

import styles from './pageArticles.module.scss';

import {Context as blogContext} from '../context/blogContext'
import ModReadPost from '../modals/modReadPost';

const PageArticles = () => {

    var {state, cxLoadBlogs} = useContext(blogContext);
    var blogState = state;

    const [showBlogModal, setShowBlogModal] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    return (
        
        <div className={styles.wrapArticles}>
        <h1 id={styles.pageTitle}>Articles</h1>
            <div className='main-bar'> 
            {(blogState.stBlogArray) ? 
                blogState.stBlogArray.map ((blog, idx)=> {
                    return (
                        <div className={styles.blogWrap} key={'blog' + idx}>   
                            <h2 className={styles.blogTitle}>{blog.fields.title}</h2>
                            <h4 className={styles.blogSubtitle}>{blog.fields.subTitle}</h4>
                            <div className={styles.wrapExcp} onClick={(ev)=>{
                                setShowBlogModal(true);
                                setSelectedPost(idx);
                                }}>
                                <img className={styles.blogImgLeft} src={blog.fields.headImgUrl}></img>
                                <div className={styles.blogIntro}>
                                    <h5 className={styles.blogExcp}>{blog.fields.excerpt}</h5>
                                    <p className={styles.introBody}>{blog.fields.intro} <span className={styles.readMore}>...read more</span></p>
                                </div>
                            </div>
                            {/* {documentToReactComponents(blog.fields.body)} */}
                        </div>
                    )
                    }
                ) : null
            }
            </div>
            { /* // modal for full blog post */}
            <ModReadPost show={showBlogModal} fnToggleShow={setShowBlogModal} postIndex={selectedPost} />

        </div>
    )

}

export default PageArticles;