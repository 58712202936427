import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import RespPlayer from '../components/RespPlayer';
import Button from '../controls/Button';

import styles from './modShowVideo.module.scss';
import thumbWasWere from '../images/gtp-was-were.jpg';

const ShowVideo = (props) => {
/*
A Modal called from modExcpAdd to facilitate the selecting or creation of Tags
*/
  
    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.videoModal}>
                    <RespPlayer videoUrl={props.videoUrl}/>
                    <Button id='btnVideoClose' legend='Close' clickFn= {()=> {
                        props.fnToggleShow(false);
                    }}/>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ShowVideo;