import React, {useState, useContext} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import axCommInstance from '../api/rfm-api';
import {Context as excpContext} from '../context/excpContext';
import {Context as authContext} from '../context/authContext';


const ModalAddTags = (props) => {
/*
A Modal called from modExcpAdd to facilitate the selecting or creation of Tags
*/

    var {cxUpdateTags} = useContext(excpContext);
    var {state} = useContext(authContext);
    const authState = state;

    const [tagName, setTagName] = useState('');
    const [msgTagExists, setMsgTagExists] = useState('');
    
    if (props.show) {
        return (
            <div className="modal">
                <div id='tagModal' className="modalContent">
                    
                <h3 id='modTagTitle'>Add Tags</h3>
                    <label id='lblTagName' htmlFor='txtTagName'>Tag Name</label>    
                    <input id='txtTagName' value={tagName} onChange={(event)=> {setTagName(event.target.value.toLowerCase())}}></input>
                    
                    <Button id='btnAddTag' legend='Add Tag' clickFn= {async ()=> {
                        const tagData={name: tagName, owner: authState.stCurrentUser.username}; 
                        const response = await axCommInstance.post('/routes/saveTag', tagData);
                        if (response.data == 'Tag Already Exists!') {
                            setMsgTagExists('Tag Already Exists! Wake Up!');
                        } else {
                            // save this in an excerpt context
                            cxUpdateTags(response.data); // calling Context
                            props.fnToggleShow(false);
                            props.isTagAdded(true);
                        }
                    }}/>

                    <Button id='btnClose' legend='Close' clickFn= {()=> {
                        setMsgTagExists('');
                        props.fnToggleShow(false);
                    }}/>
                    <h3>{msgTagExists}</h3>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalAddTags;