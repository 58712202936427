import React, {useContext, useState} from 'react';
import { Context as authContext } from '../context/authContext';

import ShowVideo from '../modals/modShowVideo';
import ElmVideoSignUp from '../elements/elmVideoSignUp'

/* --------- Video Thumbnails -------------------- */
import thumbWasWere from '../images/gtp-was-were.jpg';
import thumbFurtherFarther from '../images/gtp-further-farther.jpg';
import thumbEvadeElude from '../images/gtp-evade-elude.jpg';
import thumbLessFewer from '../images/gtp-less-fewer.jpg';
// -----------
import thumbLtx101 from '../images/ltx-why-latex.jpg';
import thumbLtx102 from '../images/ltx-explore.jpg';
import thumbLtx103 from '../images/ltx-processors.jpg';
import thumbLtx104 from '../images/ltx-hello-world.jpg';
// -----------
import thumbBts101 from '../images/thumb-bts101.jpg';
import thumbBts102 from '../images/thumb-bts102.jpg';
import thumbBts103 from '../images/thumb-bts103.jpg';
import thumbBts104 from '../images/thumb-bts104.jpg';

/* ------------ Section Header Images --------------- */
import imgGrammarTips from '../images/section-grammar-tips.jpg';
import imgLatexDocs from '../images/section-latex.jpg';
import imgBtsSectionImg from '../images/section-techwrite.jpg';


import styles from './pageFreeVideos.module.scss';


const FreeVideos = () => {

    var {state} = useContext(authContext);
    const authState = state;

    var contentful = require('contentful');
    var client = contentful.createClient({
         space: '3m56xc9oc33d',
         accessToken: 'oqR3xDgoF0fm4YuxP04DW8e6TnsUuAKe7RBPlWTm-K4'
     });
    
    const [videoUrl, setVideoUrl] = useState('');
    const [openVideoModal, setOpenVideoModal] = useState(false);
    // --------- Vimeo URLs ---------------
    const [wasWereURL, setWasWereURL] =useState('');
    const [furtherFartherURL, setFurtherFartherURL] =useState('');
    const [evadeEludeURL, setEvadeEludeURL] =useState('');
    const [lessFewerURL, setLessFewerURL] =useState('');
    //----
    const [ltx101URL, setLtx101URL] =useState('');
    const [ltx102URL, setLtx102URL] =useState('');
    const [ltx103URL, setLtx103URL] =useState('');
    const [ltx104URL, setLtx104URL] =useState('');
    //---
    const [bts101URL, setBts101URL] =useState('');
    const [bts102URL, setBts102URL] =useState('');
    const [bts103URL, setBts103URL] =useState('');
    const [bts104URL, setBts104URL] =useState('');    
    // ----- Thumbnail URLs ---------- 
    const [gtpWasWereThumb, setGtpWasWereThumb] =useState('');
    const [gtpFurtherFartherThumb, setGtpFurtherFartherThumb] =useState('');
    const [gtpEvadeEludeThumb, setGtpEvadeEludeThumb] =useState('');
    const [gtpLessFewerThumb, setGtpLessFewerThumb] =useState('');
           // ----- Video Descriptions ---------- 
    const [gtpWasWereDesc, setGtpWasWereDesc] =useState('');
    const [gtpFurtherFartherDesc, setGtpFurtherFartherDesc] =useState('');
    const [gtpEvadeEludeDesc, setGtpEvadeEludeDesc] =useState('');
    const [gtpLessFewerDesc, setGtpLessFewerDesc] =useState('');
    //---
    const [ltx101Desc, setLtx101Desc] =useState('');
    const [ltx102Desc, setLtx102Desc] =useState('');
    const [ltx103Desc, setLtx103Desc] =useState('');
    const [ltx104Desc, setLtx104Desc] =useState('');
    //---
    const [bts101Desc, setBts101Desc] =useState('');
    const [bts102Desc, setBts102Desc] =useState('');
    const [bts103Desc, setBts103Desc] =useState('');
    const [bts104Desc, setBts104Desc] =useState('');



    const fnShowVideo = (vimeoUrl) => {
        setVideoUrl(vimeoUrl);
        setOpenVideoModal(true);
    }

    /* -------------- Fetch Video assets from Contentful ------------- */
     client.getEntry('1isDWCW9VHXx7JbtkZbtBw')
        .then(function (entry) {
            console.log(entry);
           // ----- Vimeo URLS ---------- 
           //--- GTP
           setWasWereURL(entry.fields.gtpWasWereVimeo);
           setFurtherFartherURL(entry.fields.gtpFurtherFartherVimeo);
           setEvadeEludeURL(entry.fields.gtpEvadeEludeVimeo);
           setLessFewerURL(entry.fields.gtpLessFewerVimeo);
           //--- LTX
           setLtx101URL(entry.fields.ltx101Vimeo);  
           setLtx102URL(entry.fields.ltx102Vimeo);  
           setLtx103URL(entry.fields.ltx103Vimeo);  
           setLtx104URL(entry.fields.ltx104Vimeo);  
           //--- BTS
           setBts101URL(entry.fields.bts101Vimeo);  
           setBts102URL(entry.fields.bts102Vimeo);  
           setBts103URL(entry.fields.bts103Vimeo);  
           setBts104URL(entry.fields.bts104Vimeo); 
           // ----- Thumbnail URLs ---------- 
           setGtpWasWereThumb(entry.fields.gtpWasWereImgBb);
           setGtpFurtherFartherThumb(entry.fields.dtf001FurtherFartherVimeo);
           setGtpEvadeEludeThumb(entry.fields.dtf001EvadeEludeVimeo);
           setGtpLessFewerThumb(entry.fields.dtf001LessFewerVimeo);
           // ----- Video Descriptions ---------- 
           //--- GTP
           setGtpWasWereDesc(entry.fields.gtpWasWereDesc);
           setGtpFurtherFartherDesc(entry.fields.gtpFurtherFartherDesc);
           setGtpEvadeEludeDesc(entry.fields.gtpEvadeEludeDesc);
           setGtpLessFewerDesc(entry.fields.gtpLessFewerDesc);
           //--- LTX
           setLtx101Desc(entry.fields.ltx101Desc);  
           setLtx102Desc(entry.fields.ltx102Desc);
           setLtx103Desc(entry.fields.ltx103Desc);
           setLtx104Desc(entry.fields.ltx104Desc);
           //--- BTS
           setBts101Desc(entry.fields.bts101Desc);  
           setBts102Desc(entry.fields.bts102Desc);
           setBts103Desc(entry.fields.bts103Desc);
           setBts104Desc(entry.fields.bts104Desc);

       });
       
    return (
        <div className={styles.videoWrap}>
            <h1>Free Videos! ! ! </h1>

            {/* ------------------------------- Grammar Tips Section ----------------------- */}
            <div className={styles.section}>
                <img className={styles.sectionImage} src={imgGrammarTips} alt="video thumbnail"/>
            </div>
            <div className={styles.blockWrap}>
                <div className={styles.videoRow}>
                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='was-were' 
                        onClick={()=> {
                            fnShowVideo(wasWereURL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbWasWere} alt="video thumbnail"/>
                        <p>{gtpWasWereDesc}</p>
                    </div>

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='further-farther' onClick={()=> {
                        fnShowVideo(furtherFartherURL);
                    }}>
                        <img className={styles.videoThumbnail} src={thumbFurtherFarther} alt="video thumbnail"/>
                        <p>{gtpFurtherFartherDesc}</p>
                    </div>

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='evade-elude' onClick={()=> {
                        fnShowVideo(evadeEludeURL);
                    }}>
                        <img className={styles.videoThumbnail} src={thumbEvadeElude} alt="video thumbnail"/>
                        <p>{gtpEvadeEludeDesc}</p>
                    </div>

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='less-fewer' onClick={()=> {
                        fnShowVideo(lessFewerURL);
                    }}>
                        <img className={styles.videoThumbnail} src={thumbLessFewer} alt="video thumbnail"/>
                        <p>{gtpLessFewerDesc}</p>
                    </div>
                
                </div>
            
            </div>
            
            {
                // only offer signup if not already logged in.
                (authState.stCurrentUser === null ) ? <ElmVideoSignUp /> : null

            }
            

            {/* ------------------------------- LaTeX Section ----------------------- */}
            <div className={styles.section}>
                <img className={styles.sectionImage} src={imgLatexDocs} alt="video thumbnail"/>
            </div>
            <div className={styles.blockWrap}>
                <div className={styles.videoRow}>

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='ltx101' onClick={()=> {
                        fnShowVideo(ltx101URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbLtx101} alt="video thumbnail"/>
                        <p>{ltx101Desc}</p>
                    </div>    
                
                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='ltx102' onClick={()=> {
                        fnShowVideo(ltx102URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbLtx102} alt="video thumbnail"/>
                        <p>{ltx102Desc}</p>
                    </div> 
                    
                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='ltx103' onClick={()=> {
                        fnShowVideo(ltx103URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbLtx103} alt="video thumbnail"/>
                        <p>{ltx103Desc}</p>
                    </div>
                    
                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='ltx104' onClick={()=> {
                        fnShowVideo(ltx104URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbLtx104} alt="video thumbnail"/>
                        <p>{ltx104Desc}</p>
                    </div>                     
                                    
                </div>
            
            </div>

            {
                // only offer signup if not already logged in.
                (authState.stCurrentUser === null ) ? <ElmVideoSignUp /> : null

            }

            {/* ------------------------------- Technical Writing Section ----------------------- */}
            <div className={styles.section}>
                <img className={styles.sectionImage} src={imgBtsSectionImg} alt="video thumbnail"/>
            </div>
            <div className={styles.blockWrap}>
                <div className={styles.videoRow}>

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='bts101' onClick={()=> {
                        fnShowVideo(bts101URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbBts101} alt="video thumbnail"/>
                        <p>{bts101Desc}</p>
                    </div>  

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='bts102' onClick={()=> {
                        fnShowVideo(bts102URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbBts102} alt="video thumbnail"/>
                        <p>{bts102Desc}</p>
                    </div>  

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='bts103' onClick={()=> {
                        fnShowVideo(bts103URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbBts103} alt="video thumbnail"/>
                        <p>{bts103Desc}</p>
                    </div>  

                    <div className={`${styles.videoBlock} ${styles.videoReady}`} id='bts104' onClick={()=> {
                        fnShowVideo(bts104URL);
                        }}>
                        <img className={styles.videoThumbnail} src={thumbBts104} alt="video thumbnail"/>
                        <p>{bts104Desc}</p>
                    </div>  
                    
                </div>
            
            </div>

            {
                // only offer signup if not already logged in.
                (authState.stCurrentUser === null ) ? <ElmVideoSignUp /> : null
            }


            {/*
            <ReactPlayer className={styles.videoHead}
                url={'https://vimeo.com/473480271'}
                width='640px'
                height='480px' 
                controls='true'
            />                
            */}

            <ShowVideo show={openVideoModal} fnToggleShow={setOpenVideoModal} videoUrl={videoUrl}/>
         </div>
    )
}

export default FreeVideos;