import React, {useState, useContext, useEffect} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import ModalAddTags from './modAddTags';
import {Context as srcContext} from '../context/srcContext';
import {Context as authContext} from '../context/authContext';
import axCommInstance from '../api/rfm-api';

const ModalSrcRead = (props) => {

    var {cxUpdateSources, state} = useContext(srcContext);
    var srcState = state;
    var {state} = useContext(authContext);
    var authState = state;

    // console.log(state);

    const [srcFormTitle, setSrcFormTitle] = useState('');
    const [srcFormAuthor, setSrcFormAuthor] = useState('');
    const [srcFormType, setSrcFormType] = useState('');
    const [srcFormPublisher, setSrcFormPublisher] = useState('');
    const [srcFormNotes, setSrcFormNotes] = useState('');
    const [srcFormDiscipline, setSrcFormDiscipline] = useState('');
    const [srcFormUrl, setSrcFormUrl] = useState('');
    const [srcFormCitePlain, setSrcFormCitePlain] = useState('');
    const [srcFormCiteLatex, setSrcFormCiteLatex] = useState('');
    const [srcIsSample, setSrcIsSample] = useState(false);
    
    const [showModAddTags, setShowModAddTags] = useState(false);
    // for selectR 
    const [source, setSource] = useState('');
    const [tags, setTags] = useState([]);


    useEffect(()=>{
            setSrcFormTitle(srcState.stSelectedSrc.title);
            setSrcFormAuthor(srcState.stSelectedSrc.author);
            setSrcFormType(srcState.stSelectedSrc.type);
            setSrcFormPublisher(srcState.stSelectedSrc.publisher);
            setSrcFormNotes(srcState.stSelectedSrc.notes);
            setSrcFormDiscipline(srcState.stSelectedSrc.discipline);
            setSrcFormUrl(srcState.stSelectedSrc.url);
            setSrcFormCitePlain(srcState.stSelectedSrc.cite_plain);
            setSrcFormCiteLatex(srcState.stSelectedSrc.cite_latex);
            setSrcIsSample(srcState.stSelectedSrc.isSample);
    },[srcState.stSelectedSrc]);

    if (props.show) {
        return (
            <div className="modal">
                <div id='srcReadModal' className="modalContent">
                    <h3 class='modSrcHead'>Read Source</h3>
                    <label id='lblTitle' htmlFor='txtTitle'>Title: </label>    
                    <input id='txtTitle' class='srcField' value={srcFormTitle} disabled onChange={(event)=> {setSrcFormTitle(event.target.value)}}></input>

                    <label id='lblAuth' htmlFor='txtAuthor'>Author: </label>    
                    <input id='txtAuthor' value={srcFormAuthor} disabled onChange={(event)=> {setSrcFormAuthor(event.target.value)}}></input>
                   
                    <label id='lblType' htmlFor='txtType'>Type</label>    
                    <input id='txtType' value={srcFormType} disabled onChange={(event)=> {setSrcFormType(event.target.value)}}></input>

                    <label id='lblPub' htmlFor='txtPublisher'>Publisher</label>    
                    <input id='txtPublisher' disabled value={srcFormPublisher} onChange={(event)=> {setSrcFormPublisher(event.target.value)}}></input>

                    <label id='lblDisc' htmlFor='txtDiscipline'>Discipline</label>    
                    <input id='txtDiscipline' disabled value={srcFormDiscipline} onChange={(event)=> {setSrcFormDiscipline(event.target.value)}}></input>
              
                    <label id='lblUrl' htmlFor='txtUrl'>url</label>    
                    <input id='txtUrl' value={srcFormUrl} disabled onChange={(event)=> {setSrcFormUrl(event.target.value)}}></input>

                    <label id='lblNotes' htmlFor='txaNotes'>Notes</label>    
                    <textarea id='txaNotes' value={srcFormNotes} rows='5' disabled onChange={(event)=> {setSrcFormNotes(event.target.value)}}></textarea>

                    <label id='lblPlain' htmlFor='txtCitePlain'>Refer Plain</label>    
                    <input id='txtCitePlain' value={srcFormCitePlain} disabled onChange={(event)=> {setSrcFormCitePlain(event.target.value)}}></input>

                    <label id='lblLatex' htmlFor='txtCiteLatex'>Refer Latex</label>    
                    <textarea id='txtCiteLatex' rows='10' cols='40' disabled value={srcFormCiteLatex} onChange={(event)=> {setSrcFormCiteLatex(event.target.value)}}></textarea>

                    {(authState.stCurrentUser.isAdmin) ? <div>
                                                        <label id='lblIsSample' htmlFor='chkIsSample'>Is Sample</label> 
                                                        <input id='chkIsSample' checked={srcIsSample} type='checkbox' onChange={(event)=> {setSrcIsSample(event.target.checked)}}></input>
                                                        </div> 
                                                        
                                                       : null
                    } 

                    <Button id='btnSrcAddCancel' legend='Cancel' clickFn={()=>{props.fnToggleShow(false)}}/>
                    
                    
                </div>
                
            </div>
        )
    } else {
        return null;
    }
    
}

export default ModalSrcRead;