import React, {useState, useContext, useEffect} from 'react';
import RfmApi from '../api/rfm-api';
import { Context as excpContext } from '../context/excpContext';
import { Context as authContext } from '../context/authContext';
import { Context as srcContext } from '../context/srcContext';
// controls
import Button from '../controls/Button';
import ModalExpAdd from '../modals/modExpAdd';
import ModalExpRead from '../modals/modExpRead';
import SelectR from '../controls/selectR';
import axCommInstance from '../api/rfm-api';

import styles from './pageExcerpts.module.scss';
import ModalExcpDelete from '../modals/modExcpDelete.js';
import ModalManageTags from '../modals/modManageTags.js';

const PageExcerpts = ()=> {

    const [showModExpAdd, setShowModExpAdd] = useState(false);
    const [showModExpRead, setShowModExpRead] = useState(false);
    const [expList, setExpList] = useState(null);
    const [isExcpEdit, setIsExcpEdit] = useState(false);
    const [disableExcpEditBtn, setDisableExcpEditBtn] = useState(true);
    const [disableExcpDelBtn, setDisableExcpDelBtn] = useState(true);
    const [disableExcpReadBtn, setDisableExcpReadBtn] = useState(true);
    const [showModExpDel, setShowModExpDel] = useState(false);
    const [showModManageTags, setShowModManageTags] = useState(false);
    const [tagIsAdded, setTagIsAdded] = useState(false);
    const [srcFilter, setSrcFilter] = useState({});
    const [rfmTags, setRfmTags] = useState({});

    var {state, cxUpdateExcerpts, cxSetSelectedExcp} = useContext(excpContext);
    const excpState = state;
    var {state} = useContext(authContext);
    const authState = state;
    var {state, cxUpdateSources} = useContext(srcContext);
    const srcState = state;

    useEffect(()=>{
        const wrapper = async () => {
            if(expList === null) {
                let response = await axCommInstance.get('/routes/fetchExcerpts/' + authState.stCurrentUser.username);
                setExpList(response.data);
                cxUpdateExcerpts(response.data); // update context
            } else {
                setExpList(excpState.stExcerptArray);
            }
            if (srcState.stSrcArray === null) {
                const response = await axCommInstance.get('/routes/fetchSources/' + authState.stCurrentUser.username);
                cxUpdateSources(response.data);
            } 
            
        }
        wrapper();
    },
    [excpState.stExcerptArray, srcState.stSrcArray]);
    
    

    const findParents = (selectedElement) => {
    /*
    returns the parents of the passed element
    used to manage highlighting the parent div of a clicked child
    */
        var a = selectedElement;
        var els = [];
        while (a) {
            els.unshift(a);
            if (a.classList.contains(styles.excpWrap)) {
                return a;
            }
            a = a.parentElement;
        }
        return null;  // shouldn't ever happen
    }


    const handleSelect = (selectedElement) => {  // called for each div or any descendant
    /*
    Manges highlighting of the currently selected div
    */
        // remove background from previously seleced div
        if (document.getElementsByClassName(styles.expSelected).length != 0) {
            document.getElementsByClassName(styles.expSelected)[0].classList.remove(styles.expSelected);
        }
        // detect the div, or the parent of an included element

        let div_exp_wrap = findParents(selectedElement);
        if (div_exp_wrap) {
            div_exp_wrap.classList.add(styles.expSelected);  // highlight the selected div
            cxSetSelectedExcp(expList.find((excp)=>{
                return excp.title === div_exp_wrap.id;
            }));
            setDisableExcpEditBtn(false);
            setDisableExcpDelBtn(false);
            setDisableExcpReadBtn(false);
        }
    }

    return (
        <div>
            <h1 id={styles.pageTitle}>My Excerpts</h1>
            <div className={styles.sidebarRight}>
                <div className={styles.mainBar}>
                    {(expList) ?
                        expList.map((exp, idx)=>{
                        return(
                            <div id={exp.title} key={exp._id} className={styles.excpWrap} onClick = {(e)=>{handleSelect(e.target)}}>
                                <p id= {styles.title}>{(exp.title.length >= 128) ? exp.title.substring(0, 127) + '...' : exp.title}</p>
                                <p id= {styles.body}>{(exp.body.length >= 256) ? exp.body.substring(0, 255) + '...' : exp.body}</p>
                                <p id= {styles.pages}><span className={styles.titles}>Pages: </span>{exp.pages}</p>
                                <p id= {styles.use}><span className={styles.titles}>Use As: </span>{(exp.use.length >= 256) ? exp.use.substring(0, 255) + '...' : exp.use}</p>
                                <p id= {styles.source}><span className={styles.titles}>Source: </span>{exp.sourceName}</p>
                                <p id= {styles.notes}><span className={styles.titles}>Notes: </span>{(exp.notes.length >= 256) ? exp.notes.substring(0, 255) + '...' : exp.notes}</p>
                                <div id={styles.tags}><span className={styles.titles}>Tags: </span>&nbsp; 
                                    {exp.tagName.map((tag)=>{
                                        return (
                                            <span className={styles.rcmTag} key={tag}>{`${tag}`} </span>
                                        )
                                    })}
                               </div>
                            </div>
                        )
                    }): null }
                </div>

                {/* ----------------------------------- SIDEBAR ------------------------- */}
                <div className={styles.sideBar}>
                    <h3 className={styles.sidebarHeads}>Excerpt Controls</h3>
                    {/* clicking on this button opens the Add Excerpt modal */}
                    <Button id='btnAddExcerpt' className='btnStd' legend='Add Excerpt'
                        clickFn={()=>{
                            setShowModExpAdd(true)
                            setIsExcpEdit(false);
                        }
                    }/>

                    <Button id='btnEditExcerpt' className='btnStd' disabled={disableExcpEditBtn} legend='Edit Excerpt'
                        clickFn={()=>{
                            setIsExcpEdit(true);
                            setShowModExpAdd(true);
                        }
                    }/>
                    <Button id='btnDelExcerpt' className='btnStd'  disabled={disableExcpDelBtn} legend='Delete Excerpt'
                        clickFn={()=>{setShowModExpDel(true)}}/>

                    <Button id='btnReadExcerpt' className='btnStd'  disabled={disableExcpReadBtn} legend='Read Excerpt'
                        clickFn={()=>{
                            setIsExcpEdit(true);
                            setShowModExpRead(true);
                        }}/>

                    <h3 className={styles.sidebarHeads}>Tag Controls</h3>
                    <Button id='btnTagManager' className='btnStd'  legend='Manage Tags'
                        clickFn={()=>{setShowModManageTags(true)}}/>
                    <h3 className={styles.sidebarHeads}>Filter Excerpts by Source</h3>
                    {(srcState.stSrcArray) ? 
                        <SelectR    className={styles.cboSrcSelect} 
                                    type='src' 
                                    content={srcState.stSrcArray} 
                                    value={srcFilter} 
                                    multi={false} 
                                    fnSetValue={async (value) => {
                                        let response = await axCommInstance.get('/routes/fetchFilteredExcerpts/' 
                                            + authState.stCurrentUser.username
                                            + '/source/' + value.label);
                                        setExpList(response.data);
                                        setSrcFilter(value);
                                        setRfmTags({value: '', label: ''});
                                    }}
                            />
                        : null
                    }
                        
                    <h3 className={styles.sidebarHeads}>Filter Excerpts by Tag</h3>
                    {(excpState.stTagArray) ? 
                        <SelectR    
                            className={styles.cboTagSelect} 
                            type='tag' 
                            content={excpState.stTagArray} 
                            value={rfmTags} 
                            multi={false} 
                            fnSetValue={ async (valueObj)=>{
                                let response = await axCommInstance.get('/routes/fetchExcerptsByTag/' 
                                                + authState.stCurrentUser.username
                                                + '/' + valueObj.label);
                                            setExpList(response.data);
                                setRfmTags(valueObj);
                                setSrcFilter({value: '', label: ''});
                            }}
                        />
                        : null
                    }

                    <Button id='btnClrFilter' className='btnStd'  legend='Clear Filters'
                        clickFn={()=>{
                            setExpList(excpState.stExcerptArray);
                            setSrcFilter({value: '', label: ''});
                            setRfmTags({value: '', label: ''});
                        }}/>
                </div>
            </div>
            <ModalExpAdd id='modExpAdd' tagIsAdded={tagIsAdded} fnToggleTagIsAdded={setTagIsAdded}  isEdit={isExcpEdit} show={showModExpAdd} fnToggleShow={setShowModExpAdd}/>
            <ModalExpRead id='modExpRead'  isEdit={isExcpEdit} show={showModExpRead} fnToggleShow={setShowModExpRead}/>
            <ModalExcpDelete id='modExpDel' show={showModExpDel} fnToggleShow={setShowModExpDel}/>
            <ModalManageTags id='modManageTags' show={showModManageTags} fnToggleShow={setShowModManageTags}/>
        </div>

    )
}

export default PageExcerpts;
