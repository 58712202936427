import React, {useState, useContext, useEffect} from 'react';
import RfmApi from '../api/rfm-api';
import { Context as authContext } from '../context/authContext';
import { Context as srcContext } from '../context/srcContext';
// controls
import Button from '../controls/Button';
import ModalSrcAdd from '../modals/modSrcAdd';
import SelectR from '../controls/selectR';
import axCommInstance from '../api/rfm-api';
import ModalSrcDelete from '../modals/modSrcDelete';
import ModalSrcRead from '../modals/modSrcRead';

import styles from './pageSources.module.scss';

const PageSources = () => {

    // const fLbls = { // field labels
    //     author: 'Author: ',
    //     type:   'Type: ',
    //     pub:    'Publisher: ',
    //     disc:   'Discipline: ',
    //     url:    'URL: ',
    //     notes:  'Notes: ',
    //     plain:  'Cite Plain: ',
    //     latex:  'Cite Other: '
    // }

    const [srcList, setSrcList] = useState(null);
    const [srcIsEdit, setSrcIsEdit] = useState(false);
    /* --- button states --- */
    const [disableSrcEditBtn, setDisableSrcEditBtn ] = useState(true);
    const [disableSrcDelBtn, setDisableSrcDelBtn ] = useState(true);
    const [disableSrcReadBtn, setDisableSrcReadBtn ] = useState(true);

    const [showModSrcRead, setShowModSrcRead] = useState(false);
    const [showModSrcAdd, setShowModSrcAdd] = useState(false);
    const [showModSrcDelete, setShowModSrcDelete] = useState(false);

    var {cxUpdateSources, cxSetSelectedSrc, state} = useContext(srcContext);
    var srcState = state;
    var {state} = useContext(authContext);
    var authState = state;

    useEffect(() => {
        const wrap=async () => {
            if(srcList === null) {
                const response = await axCommInstance.get('/routes/fetchSources/' + authState.stCurrentUser.username);
                cxUpdateSources(response.data);
                setSrcList(response.data);
            } else {
                setSrcList(srcState.stSrcArray);
            }
        }
        wrap();
    }, [srcState.stSrcArray]);

    const findParents = (selectedElement) => {
        var a = selectedElement;
        var els = [];
        while (a) {
            els.unshift(a);
            if (a.classList.contains(styles.srcWrap)) {
                return a;
            } 
            a = a.parentElement;
        }
        return null;  // shouldn't ever happen 
    }

    const handleSelect = (selectedElement) => {  // called for each div or any descendant
        // console.log(selectedElement);
        // remove background from previously seleced div
        if (document.getElementsByClassName(styles.srcSelected).length != 0) {
            document.getElementsByClassName(styles.srcSelected)[0].classList.remove(styles.srcSelected);
        }
        // detect the div, or the parent of an included element
        let div_src_wrap = findParents(selectedElement);
        if (div_src_wrap) {
            div_src_wrap.classList.add(styles.srcSelected);  // highlight the selected div
            console.log(div_src_wrap);
            cxSetSelectedSrc(srcList.find((src)=>{
                return src.title===div_src_wrap.id;
            }));
            setDisableSrcEditBtn(false);
            setDisableSrcDelBtn(false);
            setDisableSrcReadBtn(false);
        } 
    }
     return (
        <div>   
            <h1 id={styles.pageTitle}>My Sources</h1>
            <div className={styles.sidebarRight}>
                <div className={styles.mainBar}>
                    {(srcList) ?
                        srcList.map((src)=>{
                        return(  
                            <div id={src.title} key={src._id} className={styles.srcWrap} onClick = {(e)=>{handleSelect(e.target)}}>
                                <p className={styles.lineItem} id={styles.title}>{src.title}</p>
                                <p className={styles.lineItem} id={styles.srcPgAuthor}><span className={styles.titles}>Author: </span>{src.author}</p>
                                <p className={styles.lineItem} id={styles.srcPgType}><span className={styles.titles}>Type: </span>{src.type}</p>
                                <p className={styles.lineItem} id={styles.srcPgPub}><span className={styles.titles}>Publisher: </span>{src.publisher}</p>
                                <p className={styles.lineItem} id={styles.srcPgDisc}><span className={styles.titles}>Discipline: </span>{src.discipline}</p>
                                <p className={styles.lineItem} id={styles.srcPgUrl}><span className={styles.titles}>URL: </span>{src.url}</p>
                                <p className={styles.lineItem} id={styles.srcPgNotes}><span className={styles.titles}>Notes: </span>{(src.notes.length >= 256) ? src.notes.substring(0, 255) + '...' : src.notes }</p>
                                <p className={styles.lineItem} id={styles.srcPgPlain}><span className={styles.titles}>Refer Plain: </span>{src.cite_plain}</p>
                                <p className={styles.lineItem} id={styles.srcPgLatex}><span className={styles.titles}>Refer Other: </span>{(src.cite_latex.length >= 75) ? src.cite_latex.substring(0, 74) + '...' : src.cite_latex}</p>
                            </div>
                        )
                    })
                    : null}     
                </div>
                <div className={styles.sideBar}>
                    <h3 className={styles.sidebarHeads}>Source Controls</h3> 
                    <Button id='btnAddSrc' className='btnStd' legend='Add Source' clickFn={()=>{
                        setSrcIsEdit(false);    
                        setShowModSrcAdd(true);
                        }}/>

                    <Button id='btnEditSrc' className='btnStd' legend='Edit Source' disabled={disableSrcEditBtn} clickFn={()=>{
                            setSrcIsEdit(true); 
                            setShowModSrcAdd(true);
                        }}/>
                    
                    <Button id='btnDelSrc' className='btnStd' legend='Delete Source'  disabled={disableSrcDelBtn} 
                        clickFn={()=>{setShowModSrcDelete(true)}}/>
                    
                    <Button id='btnReadSrc' className='btnStd' legend='Read Source' disabled={disableSrcReadBtn} clickFn={()=>{setShowModSrcRead(true)}}/>
                    {/*  selectors to filter by Source or Tag
                    <SelectR />
                    <SelectR />
                    */}
                </div>
            </div>  
            <ModalSrcRead id='modSrcRead' show={showModSrcRead} fnToggleShow={setShowModSrcRead}/>
            <ModalSrcAdd id='modSrcEdit' isEdit={srcIsEdit} show={showModSrcAdd} fnToggleShow={setShowModSrcAdd}/> 
            <ModalSrcDelete id='modSrcDel' show={showModSrcDelete} fnToggleShow={setShowModSrcDelete}/>
        </div>

    )
}

export default PageSources;
