import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import RespPlayer from '../components/RespPlayer';

import { BiCabinet } from 'react-icons/bi';
import { BiCalculator } from 'react-icons/bi';
import { BiReceipt } from 'react-icons/bi';
import { FcAreaChart } from 'react-icons/fc';
import { FcList } from 'react-icons/fc';
import { FcOvertime } from 'react-icons/fc';
import { FcTodoList } from 'react-icons/fc';


import styles from './elmHomeMain.module.scss';
import headImg from '../images/outside-the-box.jpg';

const ElmHomeMobile = () => {
    
    var contentful = require('contentful');
    var client = contentful.createClient({
         space: '3m56xc9oc33d',
         accessToken: 'oqR3xDgoF0fm4YuxP04DW8e6TnsUuAKe7RBPlWTm-K4'
     });

     // Contentful loaders
     const [cfHead, setCfHead] = useState('');
     const [cfHeaderImg, setCfHeaderImg] = useState('');
     const [cfSubHead, setCfSubHead] = useState('');
     const [cfIntro, setCfIntro] = useState('');
     const [cfAside1, setCfAside1] = useState('');
     const [cfSignUpHead, setCfSignUpHead] = useState('');
     const [cfSignUpBody, setCfSignUpBody] = useState('');
     const [cfVideoHead, setCfVideoHead] = useState('');
     const [cfVideoIntro, setCfVideoIntro] = useState('');
     const [cfIntroVideoUrl, setCfIntroVideoUrl] = useState('');
     const [cfUserVideoUrl, setCfUserVideoUrl] = useState('');
     const [cfSixUpHead1, setCfSixUpHead1] = useState('');
     const [cfSixUpBody1, setCfSixUpBody1] = useState('');
     const [cfSixUpHead2, setCfSixUpHead2] = useState('');
     const [cfSixUpBody2, setCfSixUpBody2] = useState('');
     const [cfSixUpHead3, setCfSixUpHead3] = useState('');
     const [cfSixUpBody3, setCfSixUpBody3] = useState('');
     const [cfSixUpHead4, setCfSixUpHead4] = useState('');
     const [cfSixUpBody4, setCfSixUpBody4] = useState('');
     const [cfSixUpHead5, setCfSixUpHead5] = useState('');
     const [cfSixUpBody5, setCfSixUpBody5] = useState('');
     const [cfSixUpHead6, setCfSixUpHead6] = useState('');
     const [cfSixUpBody6, setCfSixUpBody6] = useState('');
 

     client.getEntry('73zjtmENXkFEqvPZbMtIzJ')
         .then(function (entry) {
            setCfHead(entry.fields.header);
            setCfHeaderImg(entry.fields.headerImage);
            setCfSubHead(entry.fields.subHeader);
            setCfIntro(entry.fields.body);
            setCfAside1(entry.fields.aside1);
            setCfSignUpHead(entry.fields.signUpHead);
            setCfSignUpBody(entry.fields.signUpBody);
            setCfVideoHead(entry.fields.videoHead);
            setCfVideoIntro(entry.fields.videoIntro);
            setCfIntroVideoUrl(entry.fields.videoUrl);
            setCfUserVideoUrl(entry.fields.userVideoUrl);
            setCfSixUpHead1(entry.fields.sixUpHead1);
            setCfSixUpBody1(entry.fields.sixUpBody1);
            setCfSixUpHead2(entry.fields.sixUpHead2);
            setCfSixUpBody2(entry.fields.sixUpBody2);
            setCfSixUpHead3(entry.fields.sixUpHead3);
            setCfSixUpBody3(entry.fields.sixUpBody3);
            setCfSixUpHead4(entry.fields.sixUpHead4);
            setCfSixUpBody4(entry.fields.sixUpBody4);
            setCfSixUpHead5(entry.fields.sixUpHead5);
            setCfSixUpBody5(entry.fields.sixUpBody5);
            setCfSixUpHead6(entry.fields.sixUpHead6);
            setCfSixUpBody6(entry.fields.sixUpBody6);
        });
    
    return (
        <div>
            {/* 
            <img id={styles.imgJumbo} src={headImg} alt="home header image" />
            */}
            <div class={styles.headImgBlock}>
                <div className={styles.oneThird}><img className={styles.imgHeadBus} src='undraw_informed_decision_p2lh.svg' alt="business image" /></div>
                <div className={styles.oneThird}><img className={styles.imgHeadTech} src='undraw_software_engineer_lvl5.svg' alt="technical image" /></div>
                <div className={styles.oneThird}><img className={styles.imgHeadSci} src='undraw_science_fqhl.svg' alt="scientific image" /></div>
            </div>
            {/* ------ Introducing DraftToFinal  */}
            <h1 className={styles.head}>Welcome to Draft To Final Mobile</h1>
            <h3 className={styles.warning}>Warning: The Reference Manager App is not available when viewing the site on mobile devices.</h3>
            <p className= {styles.intro}>
            An online learning resource for all aspects of document creation, specialising in Proofreading, Technical Writing, Statistical Analysis, and building complex documents with LaTeX. Check out the 'Videos' link in the menu for a selection of free training videos, or scroll down to see details of our new Reference Manager App which is free for signed up users.
            </p>
            {/*
            <ReactPlayer className={styles.learnVideo}
                         url={cfIntroVideoUrl}
                         width='640px'
                         height='480px' 
                         controls='true'
            />
            */}
            <RespPlayer videoUrl={cfIntroVideoUrl} muted={true} autoplay={false} />
            <h3 className={styles.servicesHead}>What We Do</h3>
            <div className={styles.rowWrap}>
                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiCabinet />  {cfSixUpHead1}</h3>
                    <p className={styles.iconBlockBody}>{cfSixUpBody1}</p>
                </div>
        
                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiCalculator />  {cfSixUpHead2}</h3>
                    <p className={styles.iconBlockBody}>{cfSixUpBody2}</p>
                </div>

                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiReceipt />  {cfSixUpHead3}</h3>   
                    <p className={styles.iconBlockBody}>{cfSixUpBody3}</p>
                </div>

                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiCabinet />  {cfSixUpHead4}</h3>
                    <p className={styles.iconBlockBody}>{cfSixUpBody4}</p>
                </div>
        
                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiCalculator />  {cfSixUpHead5}</h3>
                    <p className={styles.iconBlockBody}>{cfSixUpBody5}</p>
                </div>

                <div className={styles.threePlyIconBlock}>
                    <h3 className={styles.iconBlockHead}><BiReceipt />  {cfSixUpHead6}</h3>   
                    <p className={styles.iconBlockBody}>{cfSixUpBody6}</p>
                </div>
            </div>
            
            {/* ------ Introducing Reference Manager  */}
            <h1 className={styles.head}>{cfHead}</h1>
            <h3 className={styles.warning}>The Reference Manager App is not available when viewing the site on mobile devices.</h3>
            <div className={styles.iconRibbonWrap}>
                <div className={styles.fourPlyIconBlock}>
                    <FcAreaChart className={styles.ribbonIcon}/>
                </div>
                <div className={styles.fourPlyIconBlock}>
                    <FcList className={styles.ribbonIcon}/>
                </div>
                <div className={styles.fourPlyIconBlock}>
                    <FcOvertime className={styles.ribbonIcon}/>
                </div>
                <div className={styles.fourPlyIconBlock}>
                    <FcTodoList className={styles.ribbonIcon}/>
                </div>
            </div>
            <p className= {styles.subHead}>{cfSubHead}</p>
            <p className= {styles.intro}>{cfIntro}</p>
            <p className= {styles.aside1}>{cfAside1}</p>
            <p className= {styles.signUpHead}>{cfSignUpHead}</p>
            <p className= {styles.signUpBody}>{cfSignUpBody}</p>
            <p className= {styles.videoHead}>{cfVideoHead}</p>
            <p className= {styles.videoIntro}>{cfVideoIntro}</p>
            {/*
            <ReactPlayer className={styles.learnVideo}
                         url={cfUserVideoUrl}
                         width='640px'
                         height='480px' 
                         controls='true'
            />
            */}
            <RespPlayer videoUrl={cfUserVideoUrl} muted={true} autoplay={false} />
                         
         </div>
    )
}

export default ElmHomeMobile;