import React, {useState, useContext} from 'react';
import Button from '../controls/Button';
import ReactMarkdown from 'react-markdown';

import styles from './modReadTCs.module.scss';

const ModalReadTCs = (props) => {

    const [theMarkdown, setTheMarkdown] = useState('');

    // const res = fetch(mkdLocalPath).then((response) => response.text()).then((text) => {
    //     setTheMarkdown(text )
    //   })

// Note!! markdown must not be indented! 
const markdown = `
## Introduction
These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full.   If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.

##License to use website
Unless otherwise stated, Gramenor Ltd and/or its licensors own the intellectual property rights in the website and material on the website.  Subject to the license below, all these intellectual property rights are reserved.
You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
You must not:
 
- republish material from this website (including republication on another website);
- sell, rent or sub-license material from the website;
- show any material from the website in public;
- reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;
- edit or otherwise modify any material on the website; or
- redistribute material from this website except for content specifically and expressly made available for redistribution.
 
## Acceptable use
You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.

You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
 
You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without the express written consent of Gramenor Ltd.
 
You must not use this website to transmit or send unsolicited commercial communications.
 
You must not use this website for any purposes related to marketing without Gramenor’s express written consent.
 
## No warranties
This website is provided “as is” without any representations or warranties, express or implied.  Gramenor Ltd makes no representations or warranties in relation to this website or the information and materials provided on this website.
 
Without prejudice to the generality of the foregoing paragraph, Gramenor Ltd does not warrant that:
 
- this website will be constantly available, or available at all; or
- the information on this website is complete, true, accurate or non-misleading.
 
Nothing on this website constitutes, or is meant to constitute, advice of any kind.
 
## Limitations of liability
Gramenor Ltd will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:
 
- to the extent that the website is provided free-of-charge, for any direct loss;
- for any indirect, special or consequential loss; or
- for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.
 
These limitations of liability apply even if Gramenor Ltd has been expressly advised of the potential loss.
 
## Reasonableness
By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.
 
If you do not think they are reasonable, you must not use this website.

`;

    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.tcModal} className="modalContent">
                
                <Button id={styles.btnCloseTop} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                
                <h1>Terms and Conditions</h1>
                <ReactMarkdown source={markdown} className={styles.tcContent} />
                
                {/*
                <Button id={styles.btnCloseBtm} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                */}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalReadTCs;