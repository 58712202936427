import createDataContext from './createDataContext';

const blogReducer = (state, useAction) => {
    switch (useAction.type){

    /* ------------------- Page Switcher--------------- */
        case 'loadBlogs':
            return {...state, stBlogArray: useAction.payload};

        default:
            return state;

    }
}

/* ---------------------- Functions ------------------------------- */

const cxLoadBlogs = (dispatch) => {
    return (blogArray) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'loadBlogs',
          payload: blogArray // from passed argument(s) from function call
      })
    }
  }


/* -----------------------Create Data Context ------------------------------ */

export const { Context, Provider } = createDataContext(   
    // arg 1 - the reducer
        blogReducer, 

    // arg 2 - the function(s) that will perform the action
    {
        cxLoadBlogs
    },
    // arg 3 - initial state
    {
        stBlogArray: null
    }

);