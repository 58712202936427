import createDataContext from './createDataContext';

const navReducer = (state, useAction) => {
    switch (useAction.type){

    /* ------------------- Page Switcher--------------- */
        case 'pageChange':
            return {...state, stPageControl: useAction.payload.pageControl};

        default:
            return state;

    }
}

/* ---------------------- Functions ------------------------------- */

const cxChangePage = (dispatch) => {
    return (pageControl) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'pageChange',
          payload: {pageControl} // from passed argument(s) from function call
      })
    }
  }


/* -----------------------Create Data Context ------------------------------ */

export const { Context, Provider } = createDataContext(   
    // arg 1 - the reducer
        navReducer, 

    // arg 2 - the function(s) that will perform the action
    {
        cxChangePage
    },
    // arg 3 - initial state
    {
        stPageControl: 'Home'
    }

);