import React, {useState, useContext} from 'react';
import Button from '../controls/Button';
import ReactMarkdown from 'react-markdown';

import styles from './modReadTCs.module.scss';

const ModalReadPP = (props) => {

    const [theMarkdown, setTheMarkdown] = useState('');

    // const res = fetch(mkdLocalPath).then((response) => response.text()).then((text) => {
    //     setTheMarkdown(text )
    //   })

// Note!! markdown must not be indented! 
const markdown = `
## Introduction
This privacy notice discloses the privacy practices for www.drafttofinal.com. This privacy notice applies solely to information collected by this web site. It will inform you of the following:
- What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.
- What choices are available to you regarding the use of your data.
- The security procedures in place to protect the misuse of your information.
- How you can correct any inaccuracies in the information.

## Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
We will use your information to respond to you regarding the reason you contacted us. We will not share your information with any third party outside of our organisation, other than as necessary to fulfil your request, e.g. to ship an order.
Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.

## Your Access to and Control Over Information
You may opt out of any future contacts with us at any time. You can do the following at any time by contacting us via the Contact Form given on our website:
- See what data we have about you, if any.
- Change/correct any data we have about you.
- Have us delete any data we have about you.
- Express any concern you have about our use of your data.

## Security
We take precautions to protect your information. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. 
We do not at any time hold your credit card information, banking details, or other sensitive information on our electronic systems or physical records. The password you created to sign up to our website is stored as a 256-bit hash and salt, and we do not have any access to it at any time.
The computers/servers in which we store personally identifiable information are kept in a secure environment.
If you feel that we are not abiding by this privacy policy, you should contact us immediately via the Contact form on the website.



`;

    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.tcModal} className="modalContent">
                
                <Button id={styles.btnCloseTop} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                
                <h1>Website Privacy Policy</h1>
                <ReactMarkdown source={markdown} className={styles.tcContent} />
                
                {/*
                <Button id={styles.btnCloseBtm} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                */}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalReadPP;