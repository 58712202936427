import React, {useState, useContext} from 'react';
import Button from '../controls/Button';
import axCommInstance from '../api/rfm-api';
import {Context as excpContext} from '../context/excpContext';
import {Context as authContext} from '../context/authContext';

import styles from './modForgotPassw.module.scss'

const ModalForgotPassw = (props) => {
/*
A Modal called from the Login page to facilitate resetting of password.
*/

    var {cxUpdateTags} = useContext(excpContext);
    var {state} = useContext(authContext);
    const authState = state;

    const [userName, setUserName] = useState('');
    const [msgEmailSent, setMsgEmailSent] = useState('');
    
    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.modPasswModal}>
                    
                <h3 id={styles.modPasswTitle} >Forgot Password</h3>
                    <div id={styles.userNameLbl}>
                        <label id='lblUname' htmlFor='txtUname'>User Name</label>   
                    </div>
                    <div id={styles.userNameInput}>
                        <input id='txtUName' value={userName} onChange={(event)=> {setUserName(event.target.value.toLowerCase())}}></input>
                    </div>
                    <div id={styles.submitBtn}>
                        <Button id='btnSubmit' legend='Submit' clickFn= {async ()=> {
                            const response = await axCommInstance.post('/routes/forgotPassw', {userName});
                            if (response.data.err) {
                                setMsgEmailSent(response.data.msg);
                            } else {
                                setMsgEmailSent(response.data);                        }
                        }}/>
                    </div>
                    <div id={styles.closeBtn}>   
                        <Button id='btnClose' legend='Close' clickFn= {()=> {
                            setMsgEmailSent('');
                            props.fnToggleShow(false);
                        }}/>
                    </div>
                    <h3 id={styles.emailSentMsg}>{msgEmailSent}</h3>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalForgotPassw;