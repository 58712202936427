import React from 'react';
import ReactPlayer from 'react-player';

const RespPlayer = (props)=> {

      return (
          <div className='player-wrapper'>
          <ReactPlayer
            url={props.videoUrl}
            className='react-player'
            width={props.width}
            height={props.height}
            controls
            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
          />

        </div>

        
      )
  }

  



  export default RespPlayer;