import React, {useContext, useState, useEffect} from 'react';
import { Context as navContext } from '../context/navContext';

import styles from './navItem.module.scss';

const NavItem = ({navName, active}) => {

    const {state, cxChangePage} = useContext(navContext);
    
    if (active) {  // determines the styling for selected and unselected menu items by changing the class 
        return (
            <li className={styles.nav, styles.navSel} onClick={()=> {
                cxChangePage(navName);
            }}>
                <a>{navName}</a>
            </li>
        )

    } else {
        return (
            <li className={styles.nav, styles.navUnsel} onClick={()=> {
                cxChangePage(navName);
            }}>
                <a >{navName}</a>
            </li>
        )
    }

   }

export default NavItem;
