import axios from 'axios';

const axCommInstance = axios.create({

  // baseURL:'http://localhost:5000/'


  baseURL: 'https://api-dot-sapient-tractor-283609.ew.r.appspot.com/', 
    withCredentials: false,
    headers:  {
        'Access-Control-Allow-Origin': '*', 
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, application/json'
    }


});

export default axCommInstance;
