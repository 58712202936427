
import createDataContext from './createDataContext';

const srcReducer = (state, useAction) => {
    switch (useAction.type){

    /* ------------------- ??? --------------- */
        case 'updateSources':
            return {...state, stSrcArray: useAction.payload};


        case 'selectSource':
            return {...state, stSelectedSrc: useAction.payload};

        default:
            return state;

    }
}

/* ---------------------- Functions ------------------------------- */

const cxUpdateSources = (dispatch) => {
    return (incomingSrcArray) => {
        dispatch({  // send the following to the reducer as useAction object  
          type: 'updateSources',
          payload: incomingSrcArray // from passed argument(s) from function call
      })
    }
  }

  const cxSetSelectedSrc = (dispatch) => {
    return (incomingSelectedSrc) => {
        dispatch({  // send the following to the reducer as useAction object  
          type: 'selectSource',
          payload: incomingSelectedSrc // from passed argument(s) from function call
      })
    }
  }

/* -----------------------Create Data Context ------------------------------ */

export const { Context, Provider } = createDataContext(   
    // arg 1 - the reducer
        srcReducer, 

    // arg 2 - the function(s) that will perform the action
    {
        cxUpdateSources,
        cxSetSelectedSrc
    },
    // arg 3 - initial state
    {
        stSrcArray: null,
        stSelectedSrc: {}
    }

); 

