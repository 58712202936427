import React, { useState, useEffect, useContext } from 'react';
import { Context as navContext } from '../context/navContext';
import {Context as authContext} from '../context/authContext'
import {Context as blogContext} from '../context/blogContext'
import ModReadPost from '../modals/modReadPost';

import {useCookies} from 'react-cookie';

import ElmHomeMain from  '../elements/elmHomeMain';
import ElmHomeMobile from '../elements/elmHomeMobile';

import Button from '../controls/Button';

import styles from './pageHome.module.scss';




const PageHome = () => {
    
    const [showBlogModal, setShowBlogModal] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [cookie, setCookie] = useCookies(['user']);
    var {state, cxChangeUser} = useContext(authContext);
    var authState = state;
    var {state, cxLoadBlogs} = useContext(blogContext);
    var blogState = state;
    var {state, cxChangePage} = useContext(navContext);
    var navState = state;

    var contentful = require('contentful');
    var client = contentful.createClient({
         space: '3m56xc9oc33d',
         accessToken: 'oqR3xDgoF0fm4YuxP04DW8e6TnsUuAKe7RBPlWTm-K4'
     });

 useEffect(()=>{
    if (cookie.user) {
        cxChangeUser(cookie.user);
    }
    if (blogState.stBlogArray === null) {
        client.getEntries({'content_type': 'blogPost'}).then(entries => {
            cxLoadBlogs(entries.items);
          }).catch((err)=>{
              console.log(err);
          })
    } 

}, [blogState.stBlogArray]);

    return (
        <div>
            <h1 id={styles.pageTitle}>Home</h1>
        <div className={styles.sidebarRight}>
            <div className={styles.mainBar}> 
                {(window.innerWidth >= 1200) ?
                    <ElmHomeMain />
                    :
                    <ElmHomeMobile/>
                }
                
            </div>

            <div className={styles.sideBar}>
                <h2>Recent Articles </h2> 
                <Button id='btnGoToArticles' className='btnStd' legend='See All Articles'
                        clickFn={()=>{
                            cxChangePage('Articles');
                        }
                    }/>
                {(blogState.stBlogArray) ? 
                    blogState.stBlogArray.slice(0,4).map ((blog, idx)=> {
                        return (
                            <div key={'article-'+idx} className={styles.articleStub} onClick={(ev)=>{
                                setShowBlogModal(true);
                                setSelectedPost(idx);
                                }}>
                                <div className={styles.raHeadBlock}>
                                    <h5 id={styles.raHead}>{blog.fields.title}</h5>
                                </div>    
                                <p id={styles.raExcp}>{blog.fields.excerpt}</p>
                            </div>
                        )
                    
                        }
                    ) : null
            }
            </div>
            { /* // modal for full blog post */}
            <ModReadPost show={showBlogModal} fnToggleShow={setShowBlogModal} postIndex={selectedPost} />
        </div>
    </div>
    )

}

export default PageHome;