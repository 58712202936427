import React, { Component, useState, useEffect } from 'react'
import Select from 'react-select'


const SelectR = (props) => {
      
    const handleChange = (selected) => {
        props.fnSetValue(selected);
    }

    const selOptions = props.content.map(element => {

        switch(props.type) {
        
            case 'tag':
                return {value: element.name, label: element.name};
        
            case 'src':
                return {value: element.title, label: element.title};

            default:
                // console.log(props.content);
                return element;

        }
    });
   
    return (
        <div className={props.className}>
  
            <Select
              options={selOptions}
              menuPlacement='top'
              onChange={handleChange}
              isMulti={props.multi}
              value={props.value}
              defaultValue={props.value}
            />
 
        </div>
    );
}

export default SelectR;
