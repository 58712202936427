import React, {useState, useContext} from 'react';
import SelectR from '../controls/selectR';
import ButtonNW from '../controls/ButtonNW';
import axCommInstance from '../api/rfm-api';
import {Context as srcContext} from '../context/srcContext';
import {Context as excpContext} from '../context/excpContext';
import {Context as authContext} from '../context/authContext';

import styles from './modSrcDelete.module.css';

const ModalSrcDelete = (props) => {

    var {state, cxUpdateSources} = useContext(srcContext);
    var srcState = state;
    var {state, cxUpdateExcerpts} = useContext(excpContext);
    var excpState = state;
    var {state} = useContext(authContext);
    var authState = state;

  
    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.delSrcModal} className="modalContent">
                    
                <h3 id={styles.modSrcDelTitle}>Warning! You are about to delete this Source!</h3>
                <p id={styles.modSrcDelBody}>This action will automatically delete all the Excerpts that are taken from this Source</p>
                    <div id={styles.wrap_btnDelSrcConfirm}>
                        <ButtonNW id='btnDelSrc' legend='Confirm' clickFn= {async ()=> {
                            const response = await axCommInstance.post('/routes/deleteSource/' + srcState.stSelectedSrc._id);
                            // save this in a source context
                            if (response.data == 'deleteSourceOK') {
                                // fetch the updated sources list
                                const foundSources = await axCommInstance.get('/routes/fetchSources/' + authState.stCurrentUser.username);    
                                // fetch the updated excerpts list
                                const foundExcerpts = await axCommInstance.get('/routes/fetchExcerpts/' + authState.stCurrentUser.username);    
                                // update the context
                                cxUpdateSources(foundSources.data);
                                cxUpdateExcerpts(foundExcerpts.data); 
                                props.fnToggleShow(false);
                            }
                        }}/>
                    </div>

                    <div id={styles.wrap_btnDelSrcCancel}>
                        <ButtonNW id='btnDelSrcCancel' legend='Cancel' clickFn= {()=> {
                            props.fnToggleShow(false);
                        }}/>
                    </div>

                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalSrcDelete;