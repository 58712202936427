import React, {useContext} from 'react';
import { Context as navContext } from './context/navContext';
import { Context as authContext } from './context/authContext';
import { Context as excpContext } from './context/excpContext'; 
import { Context as srcContext } from './context/srcContext';
import {useCookies} from 'react-cookie';



import './App.scss';

import Header from './components/header';
import Footer from './components/footer';
import PageHome from './pages/pageHome';
import PageArticles from './pages/pageArticles';
import PageSignUp from './pages/pageSignUp';
import PageLogin from './pages/pageLogin';
import PageExcerpts from './pages/pageExcerpts';
import PageSources from './pages/pageSources';
import PageContact from './pages/pageContact';
import FreeVideos from './pages/pageFreeVideos';

const App = () => {

  const {state, cxChangePage} = useContext(navContext);
  const {cxChangeUser} = useContext(authContext);
  const {cxUpdateTags, cxUpdateExcerpts, cxSetSelectedExcp} = useContext(excpContext);
  const {cxUpdateSources, cxSetSelectedSrc} = useContext(srcContext);

  const [cookie, setCookie, removeCookie] = useCookies(['user']);
  
  let currentPage = null;

  // const tempNav = ['Home', 'Articles', 'Login', 'Sign Up', 'References', 'Sources'];

        switch (state.stPageControl) {
            case 'Home':
                currentPage = <PageHome />;
                break;

              case 'Articles':
                currentPage = <PageArticles />;
                break;
  
            case 'Sign Up':
              currentPage = <PageSignUp />;
              break;

            case 'Log In':
              currentPage = <PageLogin />;
              break;

            case 'Log Out':
              cxChangeUser(null);
              cxUpdateTags(null); 
              cxUpdateExcerpts(null);
              cxSetSelectedExcp({});
              cxUpdateSources(null);
              cxSetSelectedSrc({});
              removeCookie('user');
              cxChangePage('Home');
              break;

            case 'My Excerpts':
              currentPage = <PageExcerpts />;
              break;

            case 'My Sources':
              currentPage = <PageSources />;
              break;

            case 'Contact':
              currentPage = <PageContact />;
              break;

              case 'Videos':
                currentPage = <FreeVideos />;
                break;

            
        }

  return (
    <div className="App">
      <Header title='RFM Header' />
      <div className='pageWrap'>
        {currentPage}
      </div>  
      
      <Footer />
    </div>
  );
}

export default App;
