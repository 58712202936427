import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDOMServer from 'react-dom/server';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Provider as NavProvider } from './context/navContext';
import { Provider as AuthProvider } from './context/authContext';
import { Provider as ExcpProvider } from './context/excpContext';
import { Provider as SrcProvider } from './context/srcContext';
import { Provider as BlogProvider } from './context/blogContext';

import { hydrate, render } from "react-dom";
 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AuthProvider>  
    <NavProvider>
      <ExcpProvider>
        <SrcProvider>
          <BlogProvider>
            <App />
          </BlogProvider>
        </SrcProvider>
      </ExcpProvider>
    </NavProvider>
  </AuthProvider>,
  document.getElementById('root'));
} else {
  render(<AuthProvider>  
    <NavProvider>
      <ExcpProvider>
        <SrcProvider>
          <BlogProvider>
            <App />
          </BlogProvider>
        </SrcProvider>
      </ExcpProvider>
    </NavProvider>
  </AuthProvider>,
  document.getElementById('root'));
}


