import React, {useState, useContext, useEffect} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import ModalAddTags from './modAddTags';
import {Context as excpContext} from '../context/excpContext';
import {Context as srcContext} from '../context/srcContext';
import {Context as authContext} from '../context/authContext';
import axCommInstance from '../api/rfm-api';

const ModalExpRead = (props) => {

    var {state, cxUpdateTags, cxUpdateExcerpts} = useContext(excpContext);
    const excpState = state;
    var {state, cxUpdateSources} = useContext(srcContext);
    const srcState = state;
    var {state} = useContext(authContext);
    const authState = state;

    // console.log(state);

    const [expFormTitle, setExpFormTitle] = useState('');
    const [expFormBody, setExpFormBody] = useState('');
    const [expFormUse, setExpFormUse] = useState('');
    const [expFormNotes, setExpFormNotes] = useState('');
    const [expFormPages, setExpFormPages] = useState('');
    const [expFormSource, setExpFormSource] = useState('');
    const [expFormTags, setExpFormTags] = useState([]);
    const [showModAddTags, setShowModAddTags] = useState(false);
    // selectRs 
    const [source, setSource] = useState('');
    const [tags, setTags] = useState([]);
    // form validation
    const [formError, setFormError] = useState('');

    useEffect(()=>{
        const wrap = async () => {  // added wrapper to stop React complaining; doesn't like async as first arg type
            if(excpState.stTagArray === null) {
                let response = await axCommInstance.get('/routes/fetchTags/'+authState.stCurrentUser.username);
                cxUpdateTags(response.data);
            } else {
                cxUpdateTags(excpState.stTagArray);
            }
            if(srcState.stSrcArray === null) {
                let response = await axCommInstance.get('/routes/fetchSources/'+authState.stCurrentUser.username);
                cxUpdateSources(response.data);
            } else {
                cxUpdateSources(srcState.stSrcArray);
            }
            if (props.isEdit) {
                setExpFormTitle(excpState.stSelectedExcp.title);
                setExpFormBody(excpState.stSelectedExcp.body);
                setExpFormPages(excpState.stSelectedExcp.pages);
                setExpFormUse(excpState.stSelectedExcp.use);
                setExpFormNotes(excpState.stSelectedExcp.notes);
                /* -- source -- */
                setExpFormSource({value: excpState.stSelectedExcp.sourceName, label: excpState.stSelectedExcp.sourceName});
                /* -- tags -- */
                setExpFormTags(excpState.stSelectedExcp.tagName.map((tag)=>{
                    return {value: tag, label: tag}
                }));

            } else {
                setExpFormTitle('');
                setExpFormBody('');
                setExpFormPages('');
                setExpFormUse('');
                setExpFormNotes('');
                setExpFormSource('');
                setExpFormTags([]);
            }
        }
        wrap();
    }, [excpState.stTagArray, srcState.stSrcArray, excpState.stSelectedExcp, props.isEdit]);

    const validateForm = () => {
    /* 
    Check that all form fields comply with the model
    */
        if(expFormTitle == '') {
            setFormError('Form Error: title');
            return false;
        }

        if(expFormBody == '') {
            setFormError('Form Error: excerpt');
            return false;
        }

        if(expFormSource == '') {
            setFormError('Form Error: source');
            return false;
        }

        setFormError('');
        return true;

    }

    if (props.show) {
        return (
            <div className="modal">
                <div id='excpReadModal' className="modalContent">
                    <h3 id='excpModalHead'>Read Excerpt</h3>
                    <h2>{formError}</h2>
                    <label id='lblTitle' htmlFor='txtTitle'>Title</label>    
                    <input id='txtTitle' value={expFormTitle} onChange={(event)=> {setExpFormTitle(event.target.value)}}></input>

                    <label id='lblBody' htmlFor='txaBody'>Excerpt</label>    
                    <textarea id='txaBody' rows='5' value={expFormBody} onChange={(event)=> {setExpFormBody(event.target.value)}}></textarea>
                   
                    <label id='lblPages' htmlFor='txtPages'>Pages</label>    
                    <input id='txtPages' value={expFormPages} onChange={(event)=> {setExpFormPages(event.target.value)}}></input>

                    <label id='lblUse' htmlFor='txaUse'>Use As</label>    
                    <textarea id='txaUse' rows='5' value={expFormUse} onChange={(event)=> {setExpFormUse(event.target.value)}}></textarea>

                    <label id='lblNotes' htmlFor='txaNotes'>Notes</label>    
                    <textarea id='txaNotes' rows='5' value={expFormNotes} onChange={(event)=> {setExpFormNotes(event.target.value)}}></textarea>
                    
                    <div id='sourceSelect'>              
                        <label id='lblSource' htmlFor='selSource'>Source</label>    
                        <div  className='selExcpSource' >{excpState.stSelectedExcp.sourceName}</div>
                               
                    {/* 
                    TODO: Change the above to a simple div which displays the Source name.    
                    */}
                    </div> 

                    <div id='tagSelect'>
                        <label id='lblTags' htmlFor='selTags'>Tags</label> 
                        <div className='selExcpTags'> {excpState.stSelectedExcp.tagName.map((tag)=>{
                            return (
                                    <span class='rcmTag' value={tag}>{`${tag}`} | </span>
                                    )
                            })}</div> 
                                
                    </div>
                    
                    <Button id='btnExpAddCancel' legend='Close' clickFn={()=>{props.fnToggleShow(false)}}/>

                </div>
            </div>
        )
    } else {
        return null;
    }
    
}

export default ModalExpRead;