import React, {useState, useContext} from 'react';
import Button from '../controls/Button';
import {Context as blogContext} from '../context/blogContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from './modReadPost.module.scss';

const ModalReadPost = (props) => {


    var {state} = useContext(blogContext);
    const blogState = state;
  
    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.postModal} className="modalContent">
                
                <Button id={styles.btnCloseTop} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                
                {(blogState.stBlogArray) 
                    ? <div className={styles.postContent}>
                        <h1>{blogState.stBlogArray[props.postIndex].fields.title}</h1>
                        <h2>{blogState.stBlogArray[props.postIndex].fields.subTitle}</h2>  
                        {documentToReactComponents(blogState.stBlogArray[props.postIndex].fields.body)}                        
                      </div>
                    : null
                }
                
                {/*
                <Button id={styles.btnCloseBtm} legend='Close' clickFn= {()=> {
                    props.fnToggleShow(false);
                }}/>
                */}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalReadPost;