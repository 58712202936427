import React, {useState, useContext, useEffect} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import ModalAddTags from './modAddTags';
import {Context as srcContext} from '../context/srcContext';
import {Context as authContext} from '../context/authContext';
import axCommInstance from '../api/rfm-api';
import ObjectCompare from '../helpers/objectCompare';

import styles from './modSrcAdd.module.css';

const ModalSrcAdd = (props) => {

    var {cxUpdateSources, state} = useContext(srcContext);
    var srcState = state;
    var {state} = useContext(authContext);
    var authState = state;

    // console.log(state);

    const [srcFormTitle, setSrcFormTitle] = useState('');
    const [srcFormAuthor, setSrcFormAuthor] = useState('');
    const [srcFormType, setSrcFormType] = useState('');
    const [srcFormPublisher, setSrcFormPublisher] = useState('');
    const [srcFormNotes, setSrcFormNotes] = useState('');
    const [srcFormDiscipline, setSrcFormDiscipline] = useState('');
    const [srcFormUrl, setSrcFormUrl] = useState('');
    const [srcFormCitePlain, setSrcFormCitePlain] = useState('');
    const [srcFormCiteLatex, setSrcFormCiteLatex] = useState('');
    const [srcIsSample, setSrcIsSample] = useState(false);
    const [formContents, setFormContents] = useState({});

    
    const [showModAddTags, setShowModAddTags] = useState(false);
    // for selectR 
    const [source, setSource] = useState('');
    const [tags, setTags] = useState([]);

    const [errMsg, setErrMsg] = useState('');


    useEffect(()=>{
        if (props.isEdit) {
            setSrcFormTitle(srcState.stSelectedSrc.title);
            setSrcFormAuthor(srcState.stSelectedSrc.author);
            setSrcFormType(srcState.stSelectedSrc.type);
            setSrcFormPublisher(srcState.stSelectedSrc.publisher);
            setSrcFormNotes(srcState.stSelectedSrc.notes);
            setSrcFormDiscipline(srcState.stSelectedSrc.discipline);
            setSrcFormUrl(srcState.stSelectedSrc.url);
            setSrcFormCitePlain(srcState.stSelectedSrc.cite_plain);
            setSrcFormCiteLatex(srcState.stSelectedSrc.cite_latex);
            setSrcIsSample(srcState.stSelectedSrc.isSample);
            setFormContents({
                owner: state.stCurrentUser.username,
                title: srcState.stSelectedSrc.title,
                author: srcState.stSelectedSrc.author,
                type: srcState.stSelectedSrc.type,
                publisher: srcState.stSelectedSrc.publisher,
                notes: srcState.stSelectedSrc.notes,
                discipline: srcState.stSelectedSrc.discipline,
                url: srcState.stSelectedSrc.url,
                cite_plain: srcState.stSelectedSrc.cite_plain,
                cite_latex: srcState.stSelectedSrc.cite_latex,
                isSample: srcState.stSelectedSrc.isSample
            });

        } else {
            clearForm();
        }
        
    },[srcState.stSelectedSrc, props.isEdit]);

    const clearForm = () => {
        setSrcFormTitle('');
        setSrcFormAuthor('');
        setSrcFormType('');
        setSrcFormPublisher('');
        setSrcFormNotes('');
        setSrcFormDiscipline('');
        setSrcFormUrl('');
        setSrcFormCitePlain('');
        setSrcFormCiteLatex('');
        setSrcIsSample(false);
    }

    const validateForm = () => {
        if (srcFormTitle == '') {
            setErrMsg('Error: Title cannot be blank!');
            return false;
        } else if (srcFormAuthor == '') {
            setErrMsg('Error: Author cannot be blank!');
            return false;
        } else if (srcFormType == '') {
            setErrMsg('Error: Type cannot be blank!');
            return false;
        } else if (srcFormPublisher == '') {
            setErrMsg('Error: Publisher cannot be blank!');
            return false;
        } else {
            setErrMsg('');
            return true;
        }
    }

    if (props.show) {
        
        return (
            <div className="modal">
                <div id='srcModal' className="modalContent">
                    <h3 class='modSrcHead'>Add / Edit Source</h3>

                    <label id='lblTitle' htmlFor='txtTitle'>Title: </label>    
                    <input id='txtTitle' class='srcField' value={srcFormTitle} onChange={(event)=> {setSrcFormTitle(event.target.value)}}></input>

                    <label id='lblAuth' htmlFor='txtAuthor'>Author: </label>    
                    <input id='txtAuthor' value={srcFormAuthor} onChange={(event)=> {setSrcFormAuthor(event.target.value)}}></input>
                   
                    <label id='lblType' htmlFor='txtType'>Type</label>    
                    <input id='txtType' value={srcFormType} onChange={(event)=> {setSrcFormType(event.target.value)}}></input>

                    <label id='lblPub' htmlFor='txtPublisher'>Publisher</label>    
                    <input id='txtPublisher' value={srcFormPublisher} onChange={(event)=> {setSrcFormPublisher(event.target.value)}}></input>

                    <label id='lblDisc' htmlFor='txtDiscipline'>Discipline</label>    
                    <input id='txtDiscipline' value={srcFormDiscipline} onChange={(event)=> {setSrcFormDiscipline(event.target.value)}}></input>
              
                    <label id='lblUrl' htmlFor='txtUrl'>url</label>    
                    <input id='txtUrl' value={srcFormUrl} onChange={(event)=> {setSrcFormUrl(event.target.value)}}></input>

                    <label id='lblNotes' htmlFor='txaNotes'>Notes</label>    
                    <textarea id='txaNotes' value={srcFormNotes} rows='10' onChange={(event)=> {setSrcFormNotes(event.target.value)}}></textarea>

                    <label id='lblPlain' htmlFor='txtCitePlain'>Refer Plain</label>    
                    <input id='txtCitePlain' value={srcFormCitePlain} onChange={(event)=> {setSrcFormCitePlain(event.target.value)}}></input>

                    <label id='lblLatex' htmlFor='txtCiteLatex'>Refer Latex</label>    
                    <textarea id='txtCiteLatex' rows='10' cols='40' value={srcFormCiteLatex} onChange={(event)=> {setSrcFormCiteLatex(event.target.value)}}></textarea>

                    {(authState.stCurrentUser.isAdmin) ? <div>
                                                        <label id='lblIsSample' htmlFor='chkIsSample'>Is Sample</label> 
                                                        <input id='chkIsSample' checked={srcIsSample} type='checkbox' onChange={(event)=> {setSrcIsSample(event.target.checked)}}></input>
                                                        </div> 
                                                        
                                                       : null} 

                    <Button id='btnSrcAddCancel' legend='Cancel' clickFn={()=>{
                        setErrMsg('');
                        props.fnToggleShow(false);
                        if (!props.isEdit) {
                            clearForm();
                        }
                    }}/>
                    
                    <Button id='btnSrcAddConfirm' legend='Confirm' clickFn={async ()=>{
                        const srcData={ owner: state.stCurrentUser.username, 
                                        title: srcFormTitle, 
                                        author: srcFormAuthor, 
                                        type: srcFormType, 
                                        publisher: srcFormPublisher, 
                                        notes: srcFormNotes, 
                                        discipline: srcFormDiscipline, 
                                        url: srcFormUrl, 
                                        cite_plain: srcFormCitePlain, 
                                        cite_latex: srcFormCiteLatex ,
                                        isSample: srcIsSample,
                                    }
                        // check if form has changed
                        if(!ObjectCompare(formContents, srcData)) {
                            // write to the server
                            if (validateForm()) {
                                if(props.isEdit) {
                                    const response = await axCommInstance.post('/routes/updateSource/' + srcState.stSelectedSrc._id, srcData);
                                        // save this in an source context
                                        if (response.data.err) {
                                            setErrMsg('Error ' + response.data.err + ': ' + response.data.msg);
                                        } else {    
                                            cxUpdateSources(response.data);
                                            props.fnToggleShow(false);
                                        }   
                                } else {
                                    const response = await axCommInstance.post('/routes/saveSource', srcData);
                                        if (response.data.err) {
                                            setErrMsg('Error ' + response.data.err + ': ' + response.data.msg)
                                        } else {  
                                            // save this in an source context
                                            cxUpdateSources(response.data);
                                            // empty the form fields
                                            clearForm();
                                            props.fnToggleShow(false);
                                            setErrMsg('');
                                        }
                                    }
                                
                            }
                        } else {
                            // form hasn't changed - do nothing
                            setErrMsg ('Nothing has changed - nothing to do.')
                        }
                    }}/>
                    <p id={styles.errMsg}>{errMsg}</p>
                </div>
                <ModalAddTags id='modAddTags' show={showModAddTags} fnToggleShow={setShowModAddTags}/> 
            </div>
        )
    } else {
        return null;
    }
    
}

export default ModalSrcAdd;