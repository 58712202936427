import React from 'react';

const Button = (props) => {

    return (
        <div id={props.id}>
            <button id={props.id} className={props.className} disabled={props.disabled} onClick={props.clickFn}>{props.legend}</button>
        </div>
    )

}

export default Button;