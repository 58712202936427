import React, {useState, useContext} from 'react';
import RfmApi from '../api/rfm-api';
import { Context as navContext } from '../context/navContext';
import { Context as authContext } from '../context/authContext';
import {useCookies} from 'react-cookie';

import styles from './pageLoginSignup.module.scss';
import loginImg from '../images/bst-intro.jpg';
import ModalForgotPassw from '../modals/modForgotPassw';



const PageLogin = () => {

    const [uName, setUname] = useState('');
    const [passw, setPassw] = useState('');
    const [showPasswModal, setShowPasswModal] =useState(false);
    var {cxChangeUser} = useContext(authContext);
    var {cxChangePage} = useContext(navContext);

    const [cookie, setCookie] = useCookies(['user']);


const validateForm = (loginData)=> {
    if (loginData.username =='') {
        alert ('Please Enter Username');
        return false;
    }
    if (loginData.password =='') {
        alert ('Please Enter Password');
        return false;
    }
    return true;
}

return (
    <div className={styles.loginWrap}>
        
        <section className={styles.loginPanel}>
        <h1 className={styles.loginHead}>Log In to Draft To Final</h1>
        <p className={styles.loginIntro}>You must be logged in to access Reference Manager</p>
            <div id={styles.imageWrap}>
                <img className={styles.loginImage} src={loginImg}></img>
            </div>
            <div id={styles.logInForm}>
                <label htmlFor='loginUname'>User Name</label>
                <input id='loginUname' onChange={(event)=> {setUname(event.target.value)}}></input>
                <label htmlFor='loginPassw'>Password</label>
                <input type='password' id='loginPassw' onChange={(event)=> {setPassw(event.target.value)}}></input>
                <a onClick={()=>{
                    setShowPasswModal(true);
                }}>Forgot Password?</a>
                <div id='loginBtn'>
                    <button  id='btnLogin' onClick={async ()=>{
                        const loginData={username:uName, password: passw}; // create key/value object
                        if (validateForm(loginData)) { // only move on if form data is valid (as far as we can determine)
                            console.log('And we\'re off!');
                            const response = await RfmApi.post('/routes/checkLogin', loginData);
                            console.log(response.data);
                            if (response.data.username !== undefined) {
                                cxChangeUser(response.data);
                                setCookie('user', response.data, {path: '/', expired: 1})
                                //go to Home page
                                cxChangePage('Home');
                            } else {
                                alert(response.data);
                            }     
                    }
                    }}>Log In</button>

                </div>

            </div>
        </section>
        <ModalForgotPassw show={showPasswModal} fnToggleShow={setShowPasswModal} />
   </div>
)



}

export default PageLogin;
