import React, {useContext, useEffect, useState} from 'react';
import { Context as navContext } from '../context/navContext';
import { Context as authContext } from '../context/authContext';
import NavItem from './navItem';
import SelectR from '../controls/selectR';

import styles from './header.module.scss'


const Header = (props) => {
    
    const [tempNav, setTempNav] = useState(['Home', 'Articles', 'Log In', 'Sign Up', 'My Excerpts', 'My Sources']);
    
    const [userFullName, setUserFullName] = useState('');
    // fudge to handle multiple contexts
    var {state} = useContext(navContext);
    const navState = state;
    var {state} = useContext(authContext);
    const authState = state;

    var logoImage = require('../images/header-blank.jpg');


    useEffect( () => { 
      if (authState.stCurrentUser === null ) {
        setTempNav(['Home', 'Articles', 'Videos', 'Log In', 'Sign Up']);
        setUserFullName('');
      } else {
        setTempNav(['Home', 'Articles', 'Videos','My Excerpts', 'My Sources',  'Log Out']);
        setUserFullName(authState.stCurrentUser.fName + ' ' + authState.stCurrentUser.lName);         
        if (window.innerWidth < 1200) {  // hide RefMgr for mobile and tablets
          setTempNav(['Home', 'Articles', 'Videos','Log Out']);
        }
      {/*
        if (window.innerHeight < 900) { // RefMgr requires 1920x1080
          setTempNav(['Home', 'Articles', 'Videos','Log Out']);
          console.log(window.innerHeight);
        }
       */}
      } 

    },[authState.stCurrentUser]);

    return (
        <div className={styles.headWrap}>
          <div className={styles.logoEtc}>
            <img  className={styles.headImg} src={logoImage} height='80px'/>
            <div>
              <p id={styles.tagline}>Professional Document Creation</p>
              <p id={styles.tagline}>Resources</p>
              <p id={styles.tagline}>Training</p>
            </div>
          </div>
          <div className="mani-head">
            <div className={styles.preHead}>
                <div className={styles.mainMenu}>
                  {
                      tempNav.map((navItem, index) => {
                          if(navItem==state.stPageControl) {  // if selected
                            return <NavItem navName={navItem} key={index} active={true} />
                          } else {
                            return <NavItem navName={navItem} key={index} active={false} />
                          }
                      })
                  }
                </div>
                {(userFullName != '') ?  <p id={styles.user}>Logged in as: {userFullName}</p> : null}
                    
            </div>
          </div>
        </div>
    );

  }

  export default Header;
