import React, {useState, useContext} from 'react';
import SelectR from '../controls/selectR';
import Button from '../controls/Button';
import axCommInstance from '../api/rfm-api';
import {Context as excpContext} from '../context/excpContext';
import {Context as authContext} from '../context/authContext';

import styles from './modExcpDelete.module.css';

const ModalExcpDelete = (props) => {

    var {state, cxUpdateExcerpts} = useContext(excpContext);
    var excpState = state;
    var {state} = useContext(authContext);
    var authState = state;

  
    if (props.show) {
        return (
            <div className="modal">
                <div id={styles.delExcpModal} className="modalContent">
                <p id={styles.modExcpDelBody}>This action cannot be reversed.</p>
                    
                <h3 id={styles.modExcpDelTitle}>Warning! You are about to delete this Excerpt!</h3>
                    
                    <Button id='btnDelExcp' legend='Confirm' clickFn= {async ()=> {
                        const response = await axCommInstance.post('/routes/deleteExcerpt/' + excpState.stSelectedExcp._id);
                        // save this in an excerpt context
                        if (response.data == 'deleteExcerptOK') {
                            // fetch the updated excerpts list
                            const foundExcerpts = await axCommInstance.get('/routes/fetchExcerpts/' + authState.stCurrentUser.username);    
                            // update the context
                            cxUpdateExcerpts(foundExcerpts.data); 
                            props.fnToggleShow(false);
                        }
                    }}/>

                    <Button id='btnDelExcpCancel' legend='Cancel' clickFn= {()=> {
                        props.fnToggleShow(false);
                    }}/>

                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ModalExcpDelete;