import React, {useState, useContext} from 'react';
import RfmApi from '../api/rfm-api';
import SelectR from '../controls/selectR';
import {Context as navContext} from '../context/navContext';
import signUpImg from '../images/bst-intro.jpg';
import styles from './pageLoginSignup.module.scss';

const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
].map((country)=> {
    return {value: country, label: country}
});

const PageSignUp = () => {

    const {cxChangePage} = useContext(navContext);

    const [email, setEmail] = useState('');
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [uName, setUname] = useState('');
    const [password, setPassw] = useState('');
    const [agreeTC, setAgreeTC] = useState(false);
    const [signUpMsg, setSignUpMsg] = useState('');
    const [disableSignUp, setDisableSignUp] = useState(false);
    const [myCountry, setMyCountry] = useState('');
    const [otherCourses, setOtherCourses]= useState(''); // contents of text area 
    const [optGrammar, setOptGrammar] = useState(false);  
    const [optLatex, setOptLatex] = useState(false);  
    const [optWriting, setOptWriting] = useState(false);  

// validates an email format
const validateEmail = (email)=>  {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/***
Validate Password
- at least 8 characters long
- contain at least one uppercase and one lowercase character
- contain at least one numeric character
***/
const validatePassword = (passw) => {
    // var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(String(passw)); // returns true/false
}

/***
Validate Username
- at least 8 characters long
***/
const validateUsername = (uname) => {
    return (uname.length >= 8);
}

const validateForm = (signUpData)=> {
    if (!validateEmail(email)) {
        setSignUpMsg ('Invalid Email Format!');
        return false;
    }
    if (signUpData.fName =='') {
        setSignUpMsg ('Please enter First Name');
        return false;
    }
    if (signUpData.lName =='') {
        setSignUpMsg ('Please enter Family Name');
        return false;       
    }
    if (signUpData.uName =='') {
        setSignUpMsg ('Please Enter Username');
        return false;       
    }
    if (!validateUsername(signUpData.uName)) {    
        setSignUpMsg ('User Name should be 8 or more characters');
        return false;
    }
    if (signUpData.password =='') {
        setSignUpMsg ('Please Enter Password');
        return false;        
    }

    if (!validatePassword(signUpData.password)) {   
        setSignUpMsg ('Password invalid - check rules above.');
        return false;     
    }
    if (!signUpData.agreeTC) {
        setSignUpMsg ('Almost there... please agree to the Terms and Conditions');
        return false;       
    }

    return true;
}   

return (
    <div className={styles.signupWrap}>
            <div className={styles.signupPanel}>
                <h1 className={styles.signupHead}>Sign Up for Reference Manager</h1>
                <p className={styles.signupIntro}>Sign up for access to Reference Manager, and notification of future free videos.</p> 
                <div id={styles.imageWrap}>
                    <img className={styles.signupImage} src={signUpImg}></img>       
                </div>
          
                <div id={styles.signUpForm}>
                    <label htmlFor='signUpEmail'>Email</label> 
                    <input id='signUpEmail' value={email} onChange={(event)=> {setEmail(event.target.value)}}></input>
                    <label htmlFor='signUpFname'>First Name</label> 
                    <input id='signUpFname' value={fName} onChange={(event)=> {setFname(event.target.value)}}></input>
                    <label htmlFor='signUpLname'>Family Name</label> 
                    <input id='signUpLname' value={lName} onChange={(event)=> {setLname(event.target.value)}}></input>
                    <label htmlFor='signUpUname'>User Name</label> 
                    <input id='signUpUname' value={uName} onChange={(event)=> {setUname(event.target.value)}}></input>
                    <SelectR    className={styles.cboSrcSelect} 
                                    content={countryList} 
                                    value={myCountry} 
                                    multi={false} 
                                    fnSetValue={setMyCountry}
                            />
                    <label htmlFor='signUpPassw'>Password</label> 
                    <input id='signUpPassw' value={password} onChange={(event)=> {setPassw(event.target.value)}}></input>
                    <p>
                        - at least 8 characters long, contain at least one uppercase and one lowercase character, and contain at least one numeric character
                    </p>
                    <div id={styles.wrapTC}>
                        <input id={styles.signUpAgreeTC} type='checkbox' checked={agreeTC} onChange={(event)=> {setAgreeTC(event.target.checked)}}></input>
                        <label id={styles.lblSignUpTC} htmlFor='signUpAgreeTC'>I agree to the Terms and Conditions</label> 
                    </div>

                    <div id={styles.wrapTC}>
                        <input id={styles.signUpEsb} type='checkbox' checked={optGrammar} onChange={(event)=> {setOptGrammar(event.target.checked)}}></input>
                        <label id={styles.lblSignUpTC} htmlFor='signUpEsb'>Essential Business Grammar</label> 
                    </div>

                    <div id={styles.wrapTC}>
                        <input id={styles.signUpLtx} type='checkbox' checked={optLatex} onChange={(event)=> {setOptLatex(event.target.checked)}}></input>
                        <label id={styles.lblSignUpTC} htmlFor='signUpLtx'>LaTeX for Business</label> 
                    </div>

                    <div id={styles.wrapTC}>
                        <input id={styles.signUpBts} type='checkbox' checked={optWriting} onChange={(event)=> {setOptWriting(event.target.checked)}}></input>
                        <label id={styles.lblSignUpTC} htmlFor='signUpBts'>Technical, Business, & Scientific Writing</label> 
                    </div>

                    <p>Other course interest?</p>
                    <textarea className='txaOtherCourses' rows='5' cols='10' value={otherCourses} onChange={(event)=>{
                        setOtherCourses(event.target.value);
                    }}></textarea>


                    <div id='signUpBtn'>
                        <button  id='btnSignUp' disabled={disableSignUp} onClick={async ()=>{
                            const signUpData={email, fName, lName, uName, password, agreeTC, optGrammar, optLatex, optWriting, otherCourses, myCountry}; // create key/value object
                            if (validateForm(signUpData)) { // only move on if form data is valid (as far as we can determine)
                                console.log('And we\'re off!');
                                const response = await RfmApi.post('/routes/saveSignUp', signUpData);
                                if (response.data.err) {
                                    setSignUpMsg(response.data.msg + ' - please choose another.');
                                } else {
                                    setSignUpMsg('Thank you for signing up. We have sent a confirmation email to your email address.');
                                    setEmail('');
                                    setFname('');
                                    setLname('');
                                    setUname('');
                                    setPassw('');
                                    setAgreeTC(false);
                                    setDisableSignUp(true);
                                    setOptGrammar(false);
                                    setOptLatex(false);
                                    setOptWriting(false);
                                    setOtherCourses('');
                                    setMyCountry('');

                                }
                            }
                        }}>Sign Up</button>
                    </div>

                    <div id='application-ok'>

                        {/* show user confirmation message */}
                        <p>{signUpMsg}</p>
                        {(signUpMsg=='Thank you for signing up. We have sent a confirmation email to your email address.') 
                            ? <a className={styles.goHome} onClick={()=>{cxChangePage('Home')}}>Return to Home Page</a> 
                            : null 
                        }
                        

                    </div>
                </div>
            </div>
        
   </div>
)

}

export default PageSignUp;