
import createDataContext from './createDataContext';

const excpReducer = (state, useAction) => {
    switch (useAction.type){

    /* ------------------- ??? --------------- */
        case 'updateTags':
            return {...state, stTagArray: useAction.payload};

        case 'updateExcerpts':
            return {...state, stExcerptArray: useAction.payload};

        case 'selectExcerpt':
            return {...state, stSelectedExcp: useAction.payload};
        case 'currentExcerpt':
            return {...state, stCurrentExcp: useAction.payload};

        default:
            return state;

    }
}

/* ---------------------- Functions ------------------------------- */

const cxUpdateTags = (dispatch) => {
    return (incomingTagArray) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'updateTags',
          payload: incomingTagArray // from passed argument(s) from function call
      })
    }
  }

  const cxUpdateExcerpts = (dispatch) => { // call reducer
    return (incomingExcerptArray) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'updateExcerpts',
          payload: incomingExcerptArray // from passed argument(s) from function call
      })
    }
  }

  const cxSetSelectedExcp = (dispatch) => {
    return (incomingSelectedExcp) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'selectExcerpt',
          payload: incomingSelectedExcp // obj containing all of the Excerpt fields
      })                                // from passed argument(s) from function call
    }
  }
  const cxSetCurrentExcp = (dispatch) => {
    return (incomingCurrentExcp) => {
        dispatch({  // send the following to the reducer as useAction object
          type: 'currentExcerpt',
          payload: incomingCurrentExcp // obj containing all of the Excerpt fields
      })                                // from passed argument(s) from function call
    }
  }
/* -----------------------Create Data Context ------------------------------ */

export const { Context, Provider } = createDataContext(
    // arg 1 - the reducer
        excpReducer,

    // arg 2 - the function(s) that will perform the actions
    {
        cxUpdateTags,
        cxUpdateExcerpts,
        cxSetSelectedExcp,
        cxSetCurrentExcp
    },
    // arg 3 - initial states
    {
        stTagArray: null,
        stExcerptArray: null,
        stSelectedExcp: {},
        stCurrentExcp:{}
    }

);
